import { MultiselectValue } from "@eman/emankit/Multiselect";
import { action, get, set } from "mobx";
import { observer } from "mobx-react";
import React, { CSSProperties, KeyboardEventHandler } from "react";
import { AfterModifier, BeforeAfterModifier, BeforeModifier, DaysOfWeekModifier, FunctionModifier,
  RangeModifier } from 'react-day-picker';

export type Modifier =
  | Date
  | RangeModifier
  | BeforeModifier
  | AfterModifier
  | BeforeAfterModifier
  | DaysOfWeekModifier
  | FunctionModifier
  | undefined
  | null;

export interface Modifiers {
  today: Modifier | Modifier[];
  outside: Modifier | Modifier[];
  [other: string]: Modifier | Modifier[] | undefined;
}


export interface BindingProps<T> {
  allowEmpty?: boolean;
  tooltip?: string;
  className?: string;
  target?: T;
  property?: string;
  errorProperty?: string;
  placeholder?: string;
  id?: any;
  type?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  iconStart?: any;
  iconStop?: any;
  textEnd?: string;
  format?: "row" | "column";
  options?: FormUtils.SelectOption[];
  emptyRow?: string;
  style?: CSSProperties;
  tabIndex?: number;
  onValueChanged?: (value: any) => void;
  labelCol?: number;
  dirty?: boolean;
  fromMonth?: Date;
  toMonth?: Date;
  disabledDays?: Modifier | Modifier[]
  formatValue?: (value: any) => any;
  parseValue?: (value: any) => any;
  valueKey?: string;
  labelKey?: string;
  values?: Array<MultiselectValue<number>>;
  oninput?: string;
  min?: number;
  max?: number;
  onkeydown?: string;
  maxLength?: number;
  minLength?: number;
  numbers?: boolean;
  characters?: boolean;
  entity?: models.Base;
  isMulti?: boolean;
  capitalize?: boolean;
  onKeyPress?: KeyboardEventHandler<T>;
  onKeyDown?: KeyboardEventHandler<T>;
  onKeyUp?: KeyboardEventHandler<T>;
  integer?: boolean;
  exclude?: string[];

  // Autocomplete
  searchSuggestions?: (value: string) => any;
  getInitialSuggestions?: (value?: string) => (T[] | Promise<T[]>);
  submitAnyUserInput?: boolean;
  renderSuggestionsOnFocus?: boolean;

  // Checkbox
  ownLabel?: string;

  // CKEditor config
  readOnly?: boolean;
}

export const setValue = (target: any, property: any, value: any, skipDirty?: boolean) => {
  // Check is dirty property
  if (target.oldValues && !target.oldValues.hasOwnProperty(property)) {
    const currentValue = get(target, property);
    set(target.oldValues, property, currentValue !== undefined ? currentValue : ''); // Only undefined convert to an empty strings
  }

  // Set value
  set(target, property, value);

  if (target.errors) {
    target.errors.delete(property);
  }

  if (skipDirty !== true && target.isDirty && target.oldValues) {
    // tslint:disable-next-line:triple-equals
    let dirtyCheck = target.oldValues[property] != value;

    // There is problem with comparsion '' and 0 because according to JS this is EQUAL!
    if ((target.oldValues[property] === '' && value === 0) || (target.oldValues[property] === 0 && value === '')) {
      dirtyCheck = true;
    }

    target.isDirty.set(property, dirtyCheck);
  }
};


@observer
export default class BindingElement<TProps extends BindingProps<TModel>, TModel> extends React.Component<TProps> {

  @action.bound
  protected setValue(value: any) {
    const {target, property, onValueChanged, dirty, parseValue, type} = this.props;

    if (type === 'number') {
      value = parseFloat(value);
    }

    if (parseValue) {
      value = parseValue(value);
    }

    // This automatically set property as changable
    if (target && property) {
      setValue(target, property, value, dirty);
    }

    if (onValueChanged) {
      onValueChanged(value);
    }
  }

  protected get value() {
    const {target, property, formatValue} = this.props as TProps;

    if (!target) {
      throw new Error(`'target' of property '${property}' prop has not been set`);
    }

    if (!property) {
      throw new Error("'property' prop has not been set");
    }


    let value = get(target, property);

    if (formatValue) {
      value = formatValue(value);
    }

    return value;
  }
}
