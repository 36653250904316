import { inject, injectable } from "inversify";

import User from "@model/User";
import ShowViewModel from "./ShowViewModel";

import TYPES from "../../inversify.types";

@injectable()
export default class UserShowVM extends ShowViewModel<User, Repository<User>> {

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>
  ) {
    super(User, repository);
  }
}
