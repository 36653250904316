import { unmanaged } from "inversify";
import { action } from 'mobx';
import BaseModel from '../../Models/BaseModel';
import ViewModel from '../ViewModel';

/**
 * Create view model.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
export default abstract class CreateViewModel<TModel extends BaseModel, TRepository extends Repository<TModel>> extends ViewModel<TModel, TRepository> implements ViewModel.Create<TModel> {
  constructor(
    @unmanaged() protected model: new() => TModel,
    @unmanaged() repository: TRepository
  ) {
    super(repository);
  }

  /**
   * On component will mount we want to clear things out.
   */
  @action
  resetEntityAndErrors() {
    this.entity = new this.model();
  }

  /**
   * Create record through repository.
   *
   * @returns {(Promise<number | undefined>)}
   * @memberof CreateViewModel
   */
  async create(): Promise<number | undefined> {
    this.currentlyFetching = true;
    const response = await this.repository.create(this.entity);

    this.currentlyFetching = false;

    return response.status ? response.original.id : undefined;
  }
}
