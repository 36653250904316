import User from "@model/User";
import EditViewModel from "@vm/Edit/EditViewModel";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";

@injectable()
export default class UserEditVM extends EditViewModel<User, Repository<User>> {

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>,
  ) {
    super(User, repository);
  }

}
