import ServiceExtension from '@model/ServiceExtension';
import { injectable } from 'inversify';
import BaseRepository from './BaseRepository';
import ApiClient from '@util/ApiClient';

@injectable()
export default class ServiceExtensionRepository extends BaseRepository<ServiceExtension> {
  get classModelName(): string {
    return 'ServiceExtension';
  }

  constructor() {
    super(ServiceExtension, 'fe/extensions', 'extension');
  }

  acceptServiceExtension(id: string) {
    const config = {
      url: this.ACCEPT_URL(id),
      method: 'PATCH' as 'PATCH',
      id: 'ACCEPT_SERVICE_EXTENSION',
    };
    return ApiClient.fetchResponse(config);
  }

  rejectServiceExtension(id: string) {
    const config = {
      url: this.REJECT_URL(id),
      method: 'PATCH' as 'PATCH',
      id: 'REJECT_SERVICE_EXTENSION',
    }
    return ApiClient.fetchResponse(config);
  }

  fetchLocs(languageCode: string) {
    const config = {
      url: `${this.uri.replace('/extensions', '')}/translation/${languageCode}`,
      method: 'GET' as 'GET',
      id: 'FETCH_EXTENSION_LOCS',
    }
    return ApiClient.fetchData(config);
  }

  private ACCEPT_URL = (id: string) => `${this.uri}/${id}/approve`;
  private REJECT_URL = (id: string) => `${this.uri}/${id}/reject`;

}
