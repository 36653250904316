
import React from 'react';
import BaseList from "@component/BaseList";
// import { FilterBarProps } from "@eman/emankit/FilterBar";
import StatisticsListVM from "@vm/List/Statistics";
import { observer } from "mobx-react";
import { lazyInject, TYPES } from "../../../inversify.config";
import { HeaderProps } from '@eman/emankit/DataTable/HeaderBar';
import StatisticsItem from '@model/StatisticsItem';
import Icon, { IconType } from "@eman/emankit/Icon";
import { DatePickerBox, GenericFormField, SelectBox } from '@util/Form';
import Button, { ButtonIconPosition, ButtonVariant } from '@eman/emankit/Button';

import "./styles.scss";

@observer
export default class StatisticsList extends BaseList<StatisticsItem, StatisticsListVM, {}, {}> {
  modelName = "statistics";
  searchable = false;
  emptyRow = this.ta("emptyRow");
  selectableRows = false;
  className = "statistics-table";

  @lazyInject(TYPES.StatisticsList)
  vm: StatisticsListVM;

  constructor(props: any) {
    super(props);

    this.state = {
      filterOpen: false,
      columnsOpen: false,
      loading: true,
      filters: {},
    };
  }

  renderActionButton(item: StatisticsItem) {
    if (item.market === 'TOTAL') {
      return null;
    }
    // tslint:disable-next-line: jsx-no-lambda
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Icon
          width={24}
          height={24}
          icon={IconType.ArrowRight}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => this.router.pageLink((this.uriHelper.show_statistics(item.market)))}
        />
      </div>
    );
  }

  headerProps(): HeaderProps | undefined {
    return undefined;
  }

  renderRow = (item: StatisticsItem, column: string) => {
    switch (column) {
      case 'action':
        return this.renderActionButton(item);
      default:
        return item[column];
    }
  };

  paginationProps() {
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange,
      label: 'results',
      hidePageSize: true,
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "market",
        type: "search",
        placeholder: 'Search',
      },
      // {
      //   id: "application",
      //   type: "search",
      //   placeholder: 'Search',
      // },
      // {
      //   id: "startDate",
      //   type: "date",
      //   placeholder: 'From',
      // },
      // {
      //   id: "endDate",
      //   type: "date",
      //   placeholder: 'To',
      // },
    ];

    return {
      hideFilterSettings: true,
      hideColumnSettings: true,
      hideReset: true,
      filters,
    };
  }
  generateAdditionalFilter = (
    property: string,
    formField: React.ReactNode,
    required?: boolean,
    label?: string,
    disabled?: boolean,
    tooltip?: string
  ) => {
    return (
      <GenericFormField
        key={property}
        target={this.vm}
        property={property}
        label={label}
        required={required}
        disabled={disabled}
        hint={tooltip}
      >
        {formField}
      </GenericFormField>
    );
  };

  otherRender() {
    return (
      <div className="row">
        <div className="col-lg-2 col-sm-3" style={{ padding: 0 }}>
          {this.generateAdditionalFilter(
            "application",
            <SelectBox
              options={[
                { label: this.ta('filter.application.option.all'), value: undefined },
                { label: this.ta('filter.application.option.pcifull'), value: 'true' },
                { label: this.ta('filter.application.option.pcisdt'), value: 'false' }
              ]}
              emptyRow={this.ta("filter.application.placeholder")}
              onValueChanged={this.vm.additionalFilterChange}
            />,
            true,
            this.ta("filter.application")
          )}
        </div>
        <div className="col-lg-2 col-sm-3" style={{ padding: 0 }}>
          {this.generateAdditionalFilter(
            "startDate",
            <DatePickerBox
              placeholder={this.ta("filter.startDate.placeholder")}
              onValueChanged={this.vm.additionalFilterChange}
            />,
            true,
            this.ta("filter.startDate")
          )}
        </div>
        <div className="col-lg-2 col-sm-3" style={{ padding: 0 }}>
          {this.generateAdditionalFilter(
            "endDate",
            <DatePickerBox
              placeholder={this.ta("filter.endDate.placeholder")}
              onValueChanged={this.vm.additionalFilterChange}
            />,
            true,
            this.ta("filter.endDate")
          )}
        </div>
        <div className="col-lg-6 col-sm-3 d-flex align-items-center justify-content-end" style={{ padding: 0 }}>
          <Button
            variant={ButtonVariant.Active}
            icon={IconType.Download}
            iconPosition={ButtonIconPosition.Left}
            iconHeight={20}
            iconWidth={20}
            // tslint:disable-next-line: jsx-no-lambda
            onClick={() => this.vm.downloadExport()}
          >
            {this.ta('filter.export')}
          </Button>
        </div>
      </div>
    );
  }

  dataProps() {
    const columns = [
      this.createField("market", { tooltip: true, width: 100 }),
      this.createField("createdExtensions", { tooltip: true, width: 70 }),
      this.createField("approvedExtensions", { tooltip: true, width: 70 }),
      this.createField("rejectedExtensions", { tooltip: true, width: 70 }),
      this.createField("expiredExtensions", { tooltip: true, width: 70 }),
      this.createField("cancelledExtensions", { tooltip: true, width: 70 }),
      this.createField("action", { width: 48, hideLabel: true, nosort: true }),
    ];

    if (this.vm.columns.length === 0) {
      this.vm.setColumns(columns.map(column => column.id));
    }

    return {
      columns,
      value: this.renderRow,
      loading: false,
      fixedHeader: true
    };
  }
}
