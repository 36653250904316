import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';
import uuid from 'uuid/v4';

export interface Crumb {
  title?: string;
  pathname: string;
  hidden?: boolean;
}

@injectable()
export default class BreadcrumbService {
  @observable crumbs: Map<string, Crumb> = new Map();

  @action
  addCrumb(crumb: Crumb): string {
    const key: string = uuid();
    this.crumbs.set(key, crumb);

    return key;
  }

  @action
  updateCrumb(key: string, crumb: Crumb) {
    this.crumbs.set(key, crumb);
  }

  @action
  removeCrumb(key: string): boolean {
    return this.crumbs.delete(key);
  }

  @computed
  get breadcrumbs(): Crumb[] {
    let crumbs: Crumb[] = [];

    this.crumbs.forEach((crumb) => {
      crumbs.push(crumb);
    });


    crumbs =  crumbs.sort((a: Crumb, b: Crumb) => {
      return a.pathname.length - b.pathname.length
    });

    if (crumbs.length && crumbs[crumbs.length - 1].hidden) {
      return [];
    } else {
      return crumbs;
    }
  }

}
