import { action, observable } from 'mobx';

import { injectable } from 'inversify';

/**
 * Abstract parent of all view models.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default abstract class ViewModelBase<TModel extends models.Base, TRepository extends Repository<TModel>> implements ViewModel.Base<TModel, TRepository> {
  /**
   * Repository to fetch data
   */
  repository: TRepository;

  /**
   * Entity (model instance).
   */
  @observable entity: TModel;


  @observable currentlyFetching: boolean;

  /**
   * Determine if VM is childVM or not
   */
  childVM: boolean = false;

  /**
   * Propagate scrolling events.
   */
  scroll: boolean = true;

  /**
   * Propagate loading progress bars.
   */
  loading: boolean = true;

  /**
   * Create class with repository.
   *
   * @param repository Base repository
   */
  constructor(repository: TRepository) {
    this.repository = repository;
  }

  /**
   * Set scrolling to false.
   */
  setAsChildVM() {
    this.childVM = true;
    this.scroll = false;
  }

  /**
   * @param entity Instance
   */
  @action
  setEntity(entity: TModel) {
    this.entity = entity;
  }

  /**
   * Parse standard api response with model or errors and assign them.
   * @param response ApiResponse
   */
  @action
  setEntityFromResponse(response: ApiResponse<TModel>) {
    if (response.status && response.entity) {
      this.setEntity(response.entity);
    }
  }
}
