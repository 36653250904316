const TYPES = {
  // Services
  Breadcrumb: Symbol('Breadcrumbs'),
  User: Symbol('CurrentUser'),
  Router: Symbol('Router'),

  // URI Helpers
  UriHelper: Symbol('UriHelper'),

  // Repositories
  CurrentUserRepository: Symbol('CurrentUserRepository'),
  ExtensionRepository: Symbol('ExtensionRepository'),
  MarketRepository: Symbol('MarketRepository'),
  UserRepository: Symbol('UserRepository'),
  ServiceExtensionRepository: Symbol('ServiceExtensionRepository'),
  StatisticsRepository: Symbol('StatisticsRepository'),
  StatisticsInfoRepository: Symbol('StatisticsInfoRepository'),
  StorageRepository: Symbol('StorageRepository'),

  // List VMs
  ExtensionList: Symbol('ExtensionListVM'),
  StatisticsList: Symbol('StatisticsListVM'),
  MarketList: Symbol('MarketListVM'),
  UserList: Symbol('UserListVM'),

  // Items VMs

  // Show VMs
  UserShow: Symbol('UserShowVM'),
  ServiceExtensionShow: Symbol('ServiceExtensionShowVM'),
  StatisticsShow: Symbol('StatisticsShowVM'),
  StatisticsInfoShow: Symbol('StatisticsInfoShowVM'),
  StorageShow: Symbol('StorageShowShowVM'),

  // Create VMs
  UserCreate: Symbol('UserCreateVM'),

  // Edit VMs
  UserEdit: Symbol('UserEditVM'),

  // Edit List VM

  // Other
  ForSelect: Symbol('ForSelectVM'),
};

export default TYPES;
