import React from "react";
import BaseList from "@component/BaseList";
// import { FilterBarProps } from "@eman/emankit/FilterBar";
import MarketListVM from "@vm/List/Market";
import { observer } from "mobx-react";
import { lazyInject, TYPES } from "../../../inversify.config";
import { HeaderProps } from "@eman/emankit/DataTable/HeaderBar";
import StatisticsItem from "@model/StatisticsItem";
import { DatePickerBox, GenericFormField, SelectBox } from "@util/Form";
import Icon, { IconType } from "@eman/emankit/Icon";

import "./styles.scss";

@observer
export default class MarketList extends BaseList<
  StatisticsItem,
  MarketListVM,
  {},
  {}
> {
  modelName = "market";
  searchable = false;
  emptyRow = this.ta("emptyRow");
  selectableRows = false;
  className = "market-table";

  @lazyInject(TYPES.MarketList)
  vm: MarketListVM;

  constructor(props: any) {
    super(props);

    this.state = {
      filterOpen: false,
      columnsOpen: false,
      loading: true,
      filters: {},
    };
  }

  headerProps(): HeaderProps | undefined {
    return undefined;
  }

  renderRow = (item: StatisticsItem, column: string) => {
    switch (column) {
      case "dealerId":
        return item.market === "TOTAL" ? null : item.dealerId;
      default:
        return item[column];
    }
  };

  paginationProps() {
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange,
      label: "results",
      hidePageSize: true,
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "dealerId",
        type: "search",
        placeholder: "Search",
      },
    ];

    return {
      hideFilterSettings: true,
      hideColumnSettings: true,
      hideReset: true,
      filters,
    };
  }
  generateAdditionalFilter = (
    property: string,
    formField: React.ReactNode,
    required?: boolean,
    label?: string,
    disabled?: boolean,
    tooltip?: string
  ) => {
    return (
      <GenericFormField
        key={property}
        target={this.vm}
        property={property}
        label={label}
        required={required}
        disabled={disabled}
        hint={tooltip}
      >
        {formField}
      </GenericFormField>
    );
  };

  otherRender() {
    return (
      <div className="row">
        <div
          className="col-lg-1 col-sm-2 d-flex align-items-center"
          style={{ padding: 0 }}
        >
          <div className="back-btn">
            <Icon
              icon={IconType.PCIArrow}
              width={24}
              height={24}
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => this.router.push(this.uriHelper.statistics())}
            />
          </div>
        </div>
        <div className="col-lg-2 col-sm-3" style={{ padding: 0 }}>
          {this.generateAdditionalFilter(
            "application",
            <SelectBox
              options={[
                {
                  label: this.ta("filter.application.option.all"),
                  value: undefined,
                },
                {
                  label: this.ta("filter.application.option.pcifull"),
                  value: "true",
                },
                {
                  label: this.ta("filter.application.option.pcisdt"),
                  value: "false",
                },
              ]}
              emptyRow={this.ta("filter.application.placeholder")}
              onValueChanged={this.vm.additionalFilterChange}
            />,
            true,
            this.ta("filter.application")
          )}
        </div>
        <div className="col-lg-2 col-sm-3" style={{ padding: 0 }}>
          {this.generateAdditionalFilter(
            "startDate",
            <DatePickerBox
              placeholder={this.ta("filter.startDate.placeholder")}
              onValueChanged={this.vm.additionalFilterChange}
            />,
            true,
            this.ta("filter.startDate")
          )}
        </div>
        <div className="col-lg-2 col-sm-3" style={{ padding: 0 }}>
          {this.generateAdditionalFilter(
            "endDate",
            <DatePickerBox
              placeholder={this.ta("filter.endDate.placeholder")}
              onValueChanged={this.vm.additionalFilterChange}
            />,
            true,
            this.ta("filter.endDate")
          )}
        </div>
        <div
          className="col-lg-4 col-sm-3 d-flex align-items-center justify-content-end"
          style={{ padding: 0 }}
        />
      </div>
    );
  }

  dataProps() {
    const columns = [
      this.createField("market", { tooltip: true, nosort: true, width: 100 }),
      this.createField("dealerId", { tooltip: true, width: 70 }),
      this.createField("createdExtensions", { tooltip: true, width: 70 }),
      this.createField("approvedExtensions", { tooltip: true, width: 70 }),
      this.createField("rejectedExtensions", { tooltip: true, width: 70 }),
      this.createField("expiredExtensions", { tooltip: true, width: 70 }),
      this.createField("cancelledExtensions", { tooltip: true, width: 70 }),
    ];

    if (this.vm.columns.length === 0) {
      this.vm.setColumns(columns.map((column) => column.id));
    }

    return {
      columns,
      value: this.renderRow,
      loading: false,
      fixedHeader: true,
    };
  }
}
