import ServiceExtension from "@model/ServiceExtension";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import ShowViewModel from "./ShowViewModel";
import EventBus, { SCROLL_TOP } from '@util/EventBus';
import { observable } from 'mobx';

@injectable()
export default class ServiceExtensionShowVM extends ShowViewModel<
ServiceExtension,
ServiceExtensionRepository<ServiceExtension>
> {
@observable externalLocs: Record<string, string> = {};

  constructor(
    @inject(TYPES.ServiceExtensionRepository)
    repository: ServiceExtensionRepository<ServiceExtension>
  ) {
    super(ServiceExtension, repository);
  }

  async fetchItem() {
    this.currentlyFetching = true;

    const response = await this.repository.show(this.id, this.childVM, this.loading);
    if (response.status && response.entity) {
      const locs = await this.repository.fetchLocs(response.entity.languageCode);
      if (locs) {
        this.externalLocs = locs;
      }
      this.setEntity(response.entity);
    }

    if (this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    // Create or update associated VMs
    this.createOrUpdateAssociatedVMS();

    this.currentlyFetching = false;

    return this.id;
  }

  async acceptServiceExtension() {
    await this.repository
      .acceptServiceExtension(this.id.toString())
      .then(() =>
        this.fetchItem().then(() => (this.entity.patchInProgress = false))
      )
      .catch(() => (this.entity.patchInProgress = false));
  }

  async rejectServiceExtension() {
    await this.repository
      .rejectServiceExtension(this.id.toString())
      .then(() =>
        this.fetchItem().then(() => (this.entity.patchInProgress = false))
      )
      .catch(() => (this.entity.patchInProgress = false));
  }
}
