// Ui components
import Layout from '@component/Layout';
import PageRoute from "@component/PageRoute";
// Views
import { observer } from 'mobx-react';
import React from 'react';
import { Router } from 'react-router';
// Others
import { uriHelper } from '../config';
// Base components
import BaseRouter from './Base';
import ServiceExtension from './ServiceExtension';
import StoragePage from '@view/Admin/StoragePage';

import Cookies from "js-cookie";
import Statistics from './Statistics';
import { Role } from '@model/BaseUser';
import Licence from '@view/Public/Licence';

const parseQuery = (queryString: string): any => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  pairs.forEach((pair: string) => {
    const tempPair = pair.split('=');
    query[decodeURIComponent(tempPair[0])] = decodeURIComponent(tempPair[1] || '');
  });

  return query;
}

@observer
export default class RootRouter extends BaseRouter {

  getPublicRoutes(): AppRoute[] {
    return [
      {
        path: '/licence',
        exact: true,
        component: Licence,
      },
      {
        path: '/',
        exact: true,
        redirect: () => {
          this.user.loginUser();
          return '';
        },
      },
      {
        path: uriHelper.extension_uri(),
        exact: false,
        component: ServiceExtension,
      },
      {
        path: '*',
        exact: true,
        redirect: () => {
          this.user.loginUser();
          return '';
        },
      },
    ];

  }

  getHeadquarterRoutes(): AppRoute[] {
    return [
      {
        path: '/licence',
        exact: true,
        component: Licence,
      },
      {
        path: '/',
        exact: true,
        redirect: () => uriHelper.statistics_uri()
      },
      {
        path: uriHelper.statistics_uri(),
        exact: false,
        component: Statistics,
      },
      {
        path: '*',
        exact: true,
        redirect: () => {
          return '/';
        },
      },
    ]
  }

  getAdminRoutes(): AppRoute[] {
    return [
      {
        path: '/licence',
        exact: true,
        component: Licence,
      },
      {
        path: '/',
        exact: true,
        component: StoragePage,
      },
      {
        path: uriHelper.extension_uri(),
        exact: false,
        component: ServiceExtension,
      },
      {
        path: uriHelper.statistics_uri(),
        exact: false,
        component: Statistics,
      },
      {
        path: '*',
        exact: true,
        redirect: () => {
          return '/';
        },
      },
    ]
  }

  render() {
    let userSection = null;
    if (this.router.location.pathname === '/callback' && window.location.search) {
      Cookies.set('PCI_TOKEN', parseQuery(window.location.search).token);
      this.user.decodeUserFromToken();
      window.location.href = window.location.href.split('?')[0];
    }

    if (!this.user.isLoggedIn) {
      userSection = (
        <>
          {this.pageRouteRender(this.getPublicRoutes())}
        </>
      );
    } else {
      userSection = (
        <>
          {this.pageRouteRender(this.user.role === Role.ADMIN ? this.getAdminRoutes() : this.getHeadquarterRoutes())}
        </>
      );
    }

    return (
      <Router history={this.router.history}>
        <Layout>
          {/* Default path */}
          <PageRoute path="/"/>
          <PageRoute path="/licence"/>
          {/* User or not user section */}
          {userSection}
        </Layout>
      </Router>
    );
  }
}
