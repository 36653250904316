import { observer } from "mobx-react";
import React from "react";

import Input from "@eman/emankit/Input";
import BindingElement, { BindingProps } from "./index";
import ViewHelpers from '@util/ViewHelpers';
import ValidationManager from '../ValidationManager';

@observer
export default class TextBox extends BindingElement<BindingProps<any>, any> {

  protected handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const { capitalize, integer, max, min, entity, property } = this.props;
    if (capitalize) {
      e.target.value = ViewHelpers.capitalizeFirstLetter(e.target.value)
    }

    if (integer) {
      if ((ValidationManager.isNormalInteger(e.target.value) || e.target.value === '') && Math.floor(Number(e.target.value)) <= (max || Infinity)) {
        this.setValue(e.target.value);
      }

      if (entity && property) {
        if (this.value !== '' && min && Math.floor(Number(this.value)) < min) {
          const propertyErrors = {};
          propertyErrors[property] = [`Must be greater than ${min}.`];
          entity.appendErrors(propertyErrors);
        } else {
          entity.clearErrorsForKey("bannerImageUrl")
        }
      }
    } else {
      this.setValue(e.target.value);
    }

    if (!e.target.value) {
      this.setValue(undefined)
    }

    if (start && end) {
      e.target.setSelectionRange(start, end);
    }
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const {
      dirty,
      formatValue,
      parseValue,
      onValueChanged,
      ...others
    } = this.props;

    return (
      <Input
        {...others}
        onChange={this.handleValueChanged}
        value={
          this.value === undefined || this.value === null ? "" : this.value
        }
      />
    );
  }
}
