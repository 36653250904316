import BaseModel from "@model/BaseModel";
import BaseRepository from "@repository/BaseRepository";
import { unmanaged } from "inversify";


export default abstract class BaseAsociatedRepository<T extends BaseModel> extends BaseRepository<T> implements AsociatedRepository<T> {
  id: string | number;

  uriBackup: string;

  constructor(
    @unmanaged() protected model: new () => T,
    @unmanaged() protected uri: string,
    @unmanaged() protected modelName: string
  ) {
    super(model, uri, modelName);
    this.uriBackup = uri;
  }


  setId(id: string | number) {
    this.id = id;
    this.uri = this.uriBackup.replace('$ID', id.toString());
  }
}
