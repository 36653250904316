import BaseComponent from "@component/BaseComponent";
import { localize } from "@util/Localization";
import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

interface InfoLabelProps {
  label: string;
  value: string;
  markColor?: string;
}

@observer
export default class InfoLabel extends BaseComponent<InfoLabelProps> {
  render() {
    const { label, value, markColor } = this.props;

    return (
      <div className="row m-0 justify-content-start info">
        <div className="d-inline-flex p-2 info-label">
          {!!markColor && <span className="info-mark" style={{ backgroundColor: markColor }} />}
          {localize(label)}
        </div>
        <div className="col-auto info-value">{value}</div>
      </div>
    );
  }
}
