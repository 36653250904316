import { injectable, postConstruct } from 'inversify';
import { action, observable } from 'mobx';

import BaseModel from '@model/BaseModel';
import EventBus, {
  ENTITY_CREATED,
  ENTITY_DELETED,
  ENTITY_UPDATE,
  REFRESH_USER_FILTER_SETTINGS,
  SCROLL_TOP,
  SHOW_TOAST
} from '@util/EventBus';
import ItemsViewModel from "../../ViewModels/Items/ItemsViewModel";

/**
 * Abstract parent of all list view models.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default abstract class ListViewModel<TModel extends BaseModel, TRepository extends Repository<TModel>> extends ItemsViewModel<TModel, TRepository> implements ViewModel.List<TModel> {
  /**
   * Total number of records for pagination.
   */
  @observable total: number = 0;

  /**
   * Pagination settings.
   */
  @observable pagination: Pagination = {
    page: 1,
    pageSize: 25
  };

  /**
   * Sortable settings.
   */
  @observable order: OrderOptions;

  /**
   * Searched value in header bar.
   */
  @observable searchValue?: string;

  /**
   * Keep track of last search value. Used to determine if Searched value in header bar.
   */
  @observable lastSearchValue?: string;

  /**
   * Selected columns.
   */
  @observable columns: string[];

  /**
   * In lists where we want to select items (via checkboxes).
   *
   * @type {TModel[]}
   */
  @observable selectedRows: TModel[] = [];

  /**
   * Data fetched alongside the list.
   */
  @observable others: any = {};

  // @observable display?: models.DisplaySetting;
  // @observable displaySettings: models.DisplaySetting[] = [];

  /**
   * Create class with repository.
   *
   * @param repository Base repository
   */
  constructor(repository: TRepository) {
    super(repository);

    // tslint:disable:no-string-literal
    this.setSettings(this.constructor['defaults'] || {});
  }

  @postConstruct()
  listenEventBus() {
    EventBus.on(ENTITY_UPDATE, this.entityCallback);
    EventBus.on(ENTITY_CREATED, this.entityCallback);
    EventBus.on(ENTITY_DELETED, this.entityCallback);
  }

  entityCallback = (params: any) => {
    if (params.identificator === this.repository.classModelName) {
      this.fetchList(false);
    }
  };

  /**
   * Set settings data.
   *
   * @param data Settings values
   */
  @action
  setSettings({order, filters, columns, visibleFilters, pagination, save}: { order?: OrderOptions, filters?: any, columns?: string[], visibleFilters?: string[], pagination?: Pagination, save?: boolean }) {
    if (order) {
      this.order = order;
    }

    // Override in case it cames from filters
    if (columns) {
      this.columns = columns;
    }

    if (visibleFilters) {
      this.visibleFilters = visibleFilters;
    }

    this.selectedItems = filters || {};
    this.pagination = pagination || { page: 1, pageSize: 25 };

    this.save = save !== undefined ? save : true;
  }

  /**
   * Initialize (method to override in children, for loading more required data)
   */
  async init(...rest: any) {
    return this.fetchList();
  }

  /**
   * Cleanup before list view, reset "silent" options before unmount.
   */
  cleanUp() {
    this.save = true;
    this.scroll = true;
    this.loading = true;
    this.clearList();
  }

  /**
   * Autocomplete for searching.
   */
  autocomplete = (value: string): Promise<string[]> => {
    return this.repository.autocomplete(value);
  };

  /**
   * Set quick search value.
   */
  @action.bound
  setSearchValue = (value: string | undefined) => {
    this.searchValue = value;
  };

  /**
   * Run export.
   *
   * @param {string} success Success message for toast.
   * @memberof ListViewModelBase
   */
  startExport(format: string): Promise<boolean> {
    return this.repository.startExport(format);
  }

  /**
   * Change pagination settings.
   *
   * @param {number} page
   * @param {number} pageSize
   * @memberof ListViewModelBase
   */
  @action
  setPageAndPageSize = (page: number, pageSize: number) => {
    this.pagination = {page, pageSize};
    this.fetchList();
  };

  /**
   * Set data from api + total number of results.
   *
   * @param {TModel[]} list
   * @param {number} total
   * @memberof ListViewModelBase
   */
  @action
  setListAndTotal(list: TModel[], total: number) {
    this.list = list;
    this.total = total;
    this.selectedRows = [];
  }

  /**
   * Fetch data from server via repository.
   *
   * @param {boolean} [scrollTop=true]
   * @memberof ListViewModelBase
   */
  fetchList = async (scrollTop: boolean = true, save: boolean = true) => {
    // Prepare filters
    const filters = {
      ...this.selectedItems
    };

    // Add "Q" for quick search value
    if (this.searchValue && this.searchValue.length > 0) {
      // If search value changed take user to page 0
      if (this.searchValue !== this.lastSearchValue) {
        this.pagination.page = 1;
      }

      filters['q'].values = this.searchValue;
    }
    // Store last searched value
    this.lastSearchValue = this.searchValue;

    this.currentlyFetching = true;

    const list = await this.repository.fetchList({
      order: this.order,
      filters,
      visibleFilters: this.visibleFilters,
      pagination: this.pagination,
      save: this.save && save,
      loading: this.loading,
      preferencePrefix: this.preferencePrefix,
    });

    if (this.save && save) {
      EventBus.trigger(REFRESH_USER_FILTER_SETTINGS);
    }

    this.others = list.others;

    this.setListAndTotal(list.list, list.total);

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    this.currentlyFetching = false;
  };

  /**
   * Clear data list.
   * @memberof ListViewModelBase
   */
  @action
  clearList() {
    this.list = [];
    this.selectedRows = [];
    this.total = 0;
    this.pagination.page = 1;
  }

  /**
   * Change sorting.
   *
   * @param {OrderOptions} sort
   * @memberof ListViewModelBase
   */
  @action
  setOrder = (order: OrderOptions) => {
    this.order = order;
    this.fetchList();
  };

  /**
   * Change filtering.
   *
   * @param {FilterValues} filters
   * @param {string[]} visible Visible filter list
   * @param {boolean} skipFetch Don't call fetchList(), view take care about it itself
   * @param {boolean} save Save the filters to user preference
   * @memberof ListViewModelBase
   */
  @action
  setFilters(filters: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean) {
    this.pagination.page = 1; // Reset pagination when filter changes
    super.setFilters(filters, visible, skipFetching, save);
  }

  /**
   * Set filters and order options together.
   *
   * @param filters Filters
   * @param sort Sort options
   */
  @action
  setListAttributes({filters, order, page, pageSize}: { filters?: FilterValues, order?: OrderOptions, page?: number, pageSize?: number }) {
    if (filters) {
      this.selectedItems = filters;
    }

    if (order) {
      this.order = order;
    }

    if (page !== undefined && pageSize !== undefined) {
      this.pagination.page = page;
      this.pagination.pageSize = pageSize;
    }

    this.fetchList();
  }

  /**
   * Change columns.
   *
   * @param columns Column list
   */
  @action
  setColumns(columns: string[]) {
    this.columns = columns;
    // this.fetchList();
  }

  /**
   * Destroy item / record / entity.
   *
   * @param {number} id Entity id
   * @param {string} success Success message toast.
   * @memberof ListViewModelBase
   */
  @action.bound
  async destroy(id: number, success: string) {
    await this.repository.destroy(id);
    EventBus.trigger(SHOW_TOAST, success);
    this.fetchList();
  }

  /**
   * Set selected rows to local variable.
   *
   * @param rows Column list
   */
  @action
  setSelectedRows = (rows: TModel[]) => {
    this.selectedRows = rows;
  }
}
