// Models
import CurrentUser from "@model/CurrentUser";
import Extension from "@model/Extension";
import User from '@model/User';
import ServiceExtension from '@model/ServiceExtension';
import StatisticsInfo from '@model/StatisticsInfo';
import StatisticsItem from '@model/StatisticsItem';
import Storage from '@model/Storage';
// List VMs
import ExtensionListVM from "@vm/List/Extension";
import MarketListVM from "@vm/List/Market";
import StatisticsListVM from "@vm/List/Statistics";
import UserListVM from "@vm/List/User";
// Repositories
import CurrentUserRepository from "@repository/CurrentUser";
import ExtensionRepository from "@repository/ExtensionRepository";
import MarketRepository from '@repository/MarketRepository';
import UserRepository from '@repository/User';
import ServiceExtensionRepository from "@repository/ServiceExtensionRepository";
import StatisticsRepository  from "@repository/StatisticsRepository";
import StatisticsInfoRepository  from "@repository/StatisticsInfoRepository";
import StorageRepository from "@repository/StorageRepository";
// Services
import Breadcrumb from '@service/Breadcrumb';
import CurrentUserService from '@service/CurrentUser';
import Router from '@service/Router';
// Uri helpers
import UriHelper from '@util/UriHelper';
// Create VMs
import UserCreateVM from '@vm/Create/User';
// Edit List VMs
// Edit VMs
import UserEditVM from '@vm/Edit/User';
// Other VMs
import ForSelectVM from '@vm/Other/ForSelect';
// Show VMs
import UserShowVM from "@vm/Show/User";
import ServiceExtensionShowVM from '@vm/Show/ServiceExtension';
import StatisticsShowVM from '@vm/Show/Statistics';
import StatisticsInfoShowVM from '@vm/Show/StatisticsInfo';
import StorageShowVM from '@vm/Show/Storage';

import * as history from 'history'
import { Container, inject, injectable, interfaces } from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import 'reflect-metadata';
import { uriHelper } from './config';
// Types
import TYPES from './inversify.types';
// VMs

const container = new Container();

// Generate container and add decorators
const {lazyInject} = getDecorators(container);

// Generate router first place
const routerHistory = history.createHashHistory({basename: '/'});
const router = new Router(routerHistory);

/**
 * !!! SORT Repositories, Services a VMs alphabetically, it prevents to merge conflicts !!!
 */

// Repositories
container.bind<AbstractCurrentUserRepository<CurrentUser>>(TYPES.CurrentUserRepository).to(CurrentUserRepository).inSingletonScope();
container.bind<Repository<Extension>>(TYPES.ExtensionRepository).to(ExtensionRepository).inSingletonScope();
container.bind<Repository<StatisticsItem>>(TYPES.MarketRepository).to(MarketRepository).inSingletonScope();
container.bind<Repository<ServiceExtension>>(TYPES.ServiceExtensionRepository).to(ServiceExtensionRepository).inSingletonScope();
container.bind<Repository<StatisticsItem>>(TYPES.StatisticsRepository).to(StatisticsRepository).inSingletonScope();
container.bind<Repository<StatisticsInfo>>(TYPES.StatisticsInfoRepository).to(StatisticsInfoRepository).inSingletonScope();
container.bind<Repository<Storage>>(TYPES.StorageRepository).to(StorageRepository).inSingletonScope();
container.bind<Repository<User>>(TYPES.UserRepository).to(UserRepository).inSingletonScope();

// Services
container
  .bind<Breadcrumb>(TYPES.Breadcrumb)
  .to(Breadcrumb)
  .inSingletonScope();
container
  .bind<CurrentUserService>(TYPES.User)
  .to(CurrentUserService)
  .inSingletonScope();
container.bind<Router>(TYPES.Router).toConstantValue(router);

// VMs
// Create VMs
container.bind<UserCreateVM>(TYPES.UserCreate).to(UserCreateVM);

// List VMs
container.bind<ExtensionListVM>(TYPES.ExtensionList).to(ExtensionListVM);
container.bind<MarketListVM>(TYPES.MarketList).to(MarketListVM);
container.bind<StatisticsListVM>(TYPES.StatisticsList).to(StatisticsListVM);
container.bind<UserListVM>(TYPES.UserList).to(UserListVM);

// Items VMs

// Show VMs
container.bind<UserShowVM>(TYPES.UserShow).to(UserShowVM);
container.bind<ServiceExtensionShowVM>(TYPES.ServiceExtensionShow).to(ServiceExtensionShowVM);
container.bind<StatisticsShowVM>(TYPES.StatisticsShow).to(StatisticsShowVM).inSingletonScope();
container.bind<StatisticsInfoShowVM>(TYPES.StatisticsInfoShow).to(StatisticsInfoShowVM);
container.bind<StorageShowVM>(TYPES.StorageShow).to(StorageShowVM);

// Edit VMs
container.bind<UserEditVM>(TYPES.UserEdit).to(UserEditVM);

// Other VMs
container.bind<interfaces.Factory<ForSelectVM<models.Base>>>(TYPES.ForSelect).toFactory<ForSelectVM<models.Base>>(() => {
  return (repository: any, propertyName?: string[], order?: { direction: string, field: string }, pagination?: { page: number, pageSize: number }) => {
    return new ForSelectVM(repository, propertyName, order, pagination);
  };
});

// Others (uri etc..
container.bind<UriHelper>(TYPES.UriHelper).toConstantValue(uriHelper);

// EditList VMs

export { container, lazyInject, TYPES, inject, injectable };
