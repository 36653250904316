import BaseRepository from '@repository/BaseRepository';
import { injectable, unmanaged } from 'inversify';
import { computed, observable } from 'mobx';

@injectable()
export default class ForSelectVM<TModel extends models.Base> {
  @observable items: TModel[] = [];

  private repository: BaseRepository<TModel>;
  private nameProperties: string[] = ['name'];
  private order: { direction: string, field: string } = { direction: 'asc', field: 'name' };
  private pagination: { page: number, pageSize: number } = { page: 0, pageSize: 1000 };

  constructor(
    @unmanaged() repository: BaseRepository<TModel>,
    @unmanaged() nameProperties?: string[],
    @unmanaged() order?: { direction: string, field: string },
    @unmanaged() pagination?: { page: number, pageSize: number },
  ) {
    this.repository = repository;

    if (nameProperties) {
      this.nameProperties = nameProperties;
    }

    if (order) {
      this.order = order;
    }

    if (pagination) {
      this.pagination = pagination;
    }

    this.fetchItems();
  }

  async fetchItems() {
    const data = await this.repository.fetchList({ order: this.order as OrderOptions, pagination: this.pagination });

    this.items = data.list;
  }

  @computed
  get selectOptions(): FormUtils.SelectOption[] {
    return this.items.map((item) => {
      return ({
        value: item.id as string,
        label: this.nameProperties.map(propertyName => item[propertyName]).join(' ')
      });
    });
  }

}
