import Show from "@component/Show";
import 'chartjs-plugin-datalabels';

import { observer } from "mobx-react";
import React from "react";
import { lazyInject, TYPES } from "src/inversify.config";
import { ChartData, ChartOptions } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import StatisticsInfoShowVM from '@vm/Show/StatisticsInfo';
import StatisticsInfo from '@model/StatisticsInfo';
import Panel from "@eman/emankit/Panel";
import InfoLabel from "@component/InfoLabel";

import "../../Extension/styles.scss";

const _round = (value: number) => value ? Math.round(value * 10) / 10 : 0;
const calcPercent = (value: number, total: number) => (value / total) * 100;
const formatPercent = (v: string | number, hideMin15?: boolean) => (hideMin15 && v < 15) ? '' : `${v || 0}%`;

const DATA_MAP = [
  { label: "status.approved", key: 'approved', color: "#72c5ed", textColor: 'white' },
  { label: "status.rejected", key: 'rejected', color: "#008fd6", textColor: 'white' },
  { label: "status.expired", key: 'expired', color: "#047ebc", textColor: 'white' },
  { label: "status.cancelled", key: 'canceled', color: "#a7c2d6", textColor: 'white' },
];

const CHART_OPTIONS: ChartOptions = {
  responsive: false,
  legend: { display: false },
  plugins: {
    datalabels: {
      color: DATA_MAP.map(item => item.textColor),
      formatter: (v) => formatPercent(v, true),
    }
  }
};

const BAR_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 25,
        callback: (v) => formatPercent(v)
      },
      gridLines: {
        drawBorder: false,
      },
    }]
  },
  plugins: {
    datalabels: {
      color: 'white',
      formatter: (v) => formatPercent(v)
    }
  }
};

@observer
export default class StatisticsStatusShow extends Show<StatisticsInfo, StatisticsInfoShowVM> {
  modelName = "statisticsInfo";

  @lazyInject(TYPES.StatisticsInfoShow)
  vm: StatisticsInfoShowVM;

  prepareDonutData() {
    const { status } = this.vm.entity;
    let total = 0;
    Object.values(status).forEach(value => total += value);

    return DATA_MAP.map(item => _round(calcPercent(status[item.key], total)) || 0);
  }

  getDoughnutData(): ChartData {
    const { entity } = this.vm;
    if (entity.status) {
      return ({
        labels: DATA_MAP.map(item => this.ta(item.label)),
        datasets: [
          {
            data: this.prepareDonutData(),
            backgroundColor: DATA_MAP.map(item => item.color),
          },
        ],
      });
    }

    return ({});
  }

  calcColors() {
    const { approvementRatio } = this.vm.entity;
    let prev = 0;
    return approvementRatio.map(item => {
      const color = item.approvementPercentage < prev ? 'rgba(2, 138, 130, 0.85)' : 'rgba(21, 175, 151, 0.85)';
      prev = item.approvementPercentage;
     return color;
    });
  }

  getBarData(): ChartData {
    const { approvementRatio } = this.vm.entity;

    if (approvementRatio) {
      return ({
        labels: approvementRatio.map(item => item.month + " " + item.year),
        datasets: [{
          data: approvementRatio.map(item => item.approvementPercentage),
          backgroundColor: this.calcColors(),
          minBarLength: 15,
        }]
      });
    }

    return ({});
  };

  renderLegend() {
    const { status } = this.vm.entity;
    if (!!status) {
      let total = 0;
      Object.values(status).forEach(value => total += value);

      return (
        DATA_MAP.map((item, index) => (
          <div key={`${item.key}-${index}`}>
            <InfoLabel
              label={this.ta(item.label)}
              value={`(${formatPercent(_round(calcPercent(status[item.key], total)))})`}
              markColor={item.color}
            />
            <br />
          </div>
        ))
      );
    }
    return [];
  }

  render() {
    if (this.vm.currentlyFetching) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="charts-title">
            <span>{"Status type".toUpperCase()}</span>
          </div>
          <Panel>
            <div className="row">
              <div className="col-lg-6">
                <div className="charts-wrapper">
                  <div className="legend">
                    {this.renderLegend()}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Pie height={200} data={this.getDoughnutData()} options={CHART_OPTIONS} />
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-lg-6">
          <div className="charts-title">
            <span>{"Approvement ratio".toUpperCase()}</span>
          </div>
          <Panel className="bar-panel">
            <div className="charts-wrapper">
              <Bar data={this.getBarData()} options={BAR_OPTIONS} />
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}
