import Storage from "@model/Storage";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import ShowViewModel from "./ShowViewModel";
import BaseRepository from '@repository/BaseRepository';

@injectable()
export default class StorageShowVM extends ShowViewModel<
Storage,
BaseRepository<Storage>
> {
  constructor(
    @inject(TYPES.StorageRepository)
    repository: BaseRepository<Storage>
  ) {
    super(Storage, repository);
  }

  init(id: number | string | void) {
    this.setId('');
  }
}
