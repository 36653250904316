import { action, observable } from "mobx";

import BaseModel from "@model/BaseModel";
import ListViewModel from "@vm/List/ListViewModel";

export default class AsociatedListViewModel<TModel extends BaseModel, TRepository extends AsociatedRepository<TModel>> extends ListViewModel<TModel, TRepository> implements ViewModel.Asociated{
  @observable parentId: string | number;

  async init(parentId?: number | string) {
    if (this.parentId === undefined && !parentId) {
      throw new Error('You have to set parentId first to use this asociated VM');
    }
    if (parentId) {
      this.setParentId(parentId);
    }
    super.init();
  }

  @action
  setParentId(parentId: number | string) {
    this.parentId = parentId;
    this.repository.setId(parentId);
    this.setAsChildVM();
  }
}
