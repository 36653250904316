import BaseComponent from "@component/BaseComponent";
import { observer } from "mobx-react";
import React from "react";
import ServiceExtension from "@model/ServiceExtension";
import Panel from "@eman/emankit/Panel";
import { String } from "typescript-string-operations";

interface ExtensionBasicInfoProps {
  entity: ServiceExtension;
  localize: (key: string) => string;
  formatter: Intl.NumberFormat;
}

@observer
export default class ExtensionBasicInfo extends BaseComponent<
  ExtensionBasicInfoProps
> {

  format(entity: ServiceExtension, formatter: Intl.NumberFormat) {
    return `${formatter.format(entity.approvedPrice)} ${entity.currency}`;
  }

  render() {
    const { entity, localize, formatter } = this.props;

    return (
      <Panel>
        <div className="row extension-basic-info">
          <div className="col-lg-6 greeting-wrapper">
            <div className="greeting">
              {String.Format(localize("greeting.label"), entity.greeting)}
            </div>
            <h5 className="greeting-description">{localize('found.new.defect')}</h5>
          </div>
          <div className="col-lg-6">
            {entity.isServiceScheduled && <React.Fragment>
              <div className="status">{localize("already.scheduled")}</div>
              <div className="separator" />
            </React.Fragment>}
            <label className="action">{entity.action}</label>
            <div className="d-flex justify-content-between approved-price">
              <h6>{localize("approved.price.label")}</h6>
              <h6>
                {this.format(entity, formatter)}
              </h6>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}
