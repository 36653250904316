import upperFirst from "lodash/upperFirst";
import React from "react";
import { vokativ } from "vokativ";

import Badge from "@eman/emankit/Badge";
import { BaseColor } from "@eman/emankit/Colors";

import { GenericFormField } from "@util/Form";

import { localize } from "@util/Localization";

class ViewHelpers {
  numberWithSpaces(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  booleanOptions(numeric: boolean = false) {
    return [
      {
        id: numeric ? "1" : "true",
        name: localize("bool.yes"),
      },
      {
        id: numeric ? "0" : "false",
        name: localize("bool.no"),
      },
    ];
  }

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  booleanStringify(value: boolean | number): string {
    return localize(`bool.${!!value ? "yes" : "no"}`);
  }

  booleanToBadge(
    value: boolean | number,
    reverse: boolean = false
  ): React.ReactNode {
    return (
      <Badge color={!!value !== reverse ? BaseColor.Green : BaseColor.Red}>
        {this.booleanStringify(value)}
      </Badge>
    );
  }

  sortAlphabetically(array: string[]) {
    return array.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Generate enum item badge.
   * @param item Enumeration item
   */
  enumItemBadge(item?: models.EnumType): React.ReactNode {
    if (item) {
      return <Badge color={item.options.color}>{item.name}</Badge>;
    } else {
      return null;
    }
  }

  fileSize(sizeInBytes: number): string {
    let unit: string = "B";
    let size: number = sizeInBytes;

    if (size >= 1024 * 1024) {
      unit = "MB";
      size = Math.ceil((size / (1024 * 1024)) * 100) / 100;
    } else if (size >= 1024) {
      unit = "KB";
      size = Math.ceil((size / 1024) * 100) / 100;
    }

    return `${size} ${unit}`;
  }

  nameInVocative(name: string) {
    return upperFirst(vokativ(name));
  }

  generateFormField(
    component: any,
    property: string,
    formField: React.ReactNode,
    required?: boolean,
    label?: string
  ) {
    return (
      <GenericFormField
        key={property}
        target={component.props.entity}
        property={property}
        label={label || component.ta(property)}
        required={required}
      >
        {formField}
      </GenericFormField>
    );
  }
}

export default new ViewHelpers();
