import { History, LocationDescriptorObject } from 'history';
import { action } from 'mobx';
import { RouterStore as BaseRouterStore, syncHistoryWithStore } from 'mobx-react-router';
import qs from 'query-string';

export default class Router extends BaseRouterStore implements Services.Router {
  constructor(history?: History) {
    super();

    if (history) {
      this.history = syncHistoryWithStore(history, this);
    }
  }

  @action.bound
  pageLink(pathname: string, queryString?: any) {
    const location: LocationDescriptorObject = {};

    location.pathname = pathname;

    if (queryString) {
      location.search = qs.stringify(queryString, {arrayFormat: 'bracket'});
    }
 
    this.push(location);
  }

  // Set QueryString
  @action
  updateQuery(obj: any) {
    const location = this.history.location;
    location.search = qs.stringify(obj, {arrayFormat: 'bracket'});
    this.push(location);
  }

  getQuery() {
    const search = this.history.location.search;
    if (search.length === 0) {
      return undefined;
    } else {
      return qs.parse(search, {arrayFormat: 'bracket'});
    }
  }

  getPathName() {
    return this.history.location.pathname;
  }

  @action.bound
  login() {
    this.push('/login');
  }

  @action.bound
  resetPassword() {
    this.push('/reset_password');
  }

}
