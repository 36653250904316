import User from '@model/User';
import { injectable } from 'inversify';
import BaseRepository from './BaseRepository';

@injectable()
export default class UserRepository extends BaseRepository<User> {
  get classModelName(): string {
    return 'User';
  }
 
  constructor() {
    super(User, 'users', 'user');
  }
}
