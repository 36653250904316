import downloadjs from "downloadjs";
import { inject, injectable, postConstruct } from "inversify";

import ListViewModel from "@vm/List/ListViewModel";

import TYPES from "../../inversify.types";
import { action, observable } from 'mobx';
import StatisticsItem from '@model/StatisticsItem';
import { formatDate, formatRequestDate } from "@util/DateFormats";

@injectable()
export default class StatisticsListVM extends ListViewModel<StatisticsItem, StatisticsRepository<StatisticsItem>> {
  static defaults = {
    order: {
      field: 'market',
      direction: 'desc',
    },
    columns: [],
    // visibleFilters: ['application', 'startDate', 'endDate'],
  };

  @observable application?: string | number;
  @observable startDate?: Date;
  @observable endDate?: Date;

  constructor(
    @inject(TYPES.StatisticsRepository) repository: StatisticsRepository<StatisticsItem>
  ) {
    super(repository);
  }

  @postConstruct()
  listenEventBus() {
    //
  }

  @action.bound
  cancelSelection() {
    this.setSelectedRows([]);
  }

  @action.bound
  additionalFilterChange() {
    this.prepareFilters(this.selectedItems);
    this.fetchList();
  }


  prepareFilters(items: FilterValues) {
    const dateRangeFilters: Array<{ propertyName: string, from?: string, to?: string }> = [];
    const propertyValueFilters: Array<{ propertyName: string, values: any[] }> = [];
    const numberRangeFilters: Array<{ propertyName: string, from?: number, to?: number }> = [];

    // additional dates
    if (this.startDate || this.endDate) {
      const dateFilter: { propertyName: string, from?: string, to?: string } = { propertyName: "createdDateTime" };
      if (this.startDate) {
        dateFilter.from = formatRequestDate(this.startDate);
      }

      if (this.endDate) {
        dateFilter.to = formatRequestDate(this.endDate);
      }
      dateRangeFilters.push(dateFilter);
    }

    // additional application
    if (this.application) {
      const propertyName = 'isPciFull';
      const values: any = [];
      values.push(this.application);
      propertyValueFilters.push({ propertyName, values });
    }

    // values
    ["market"].forEach(propertyName => {
      if (items[propertyName] && items[propertyName].values) {
        let values: any[] = [];
        if (typeof items[propertyName].values === "object") {
          values = items[propertyName].values as any[];
        } else {
          values.push(items[propertyName].values);
        }
        propertyValueFilters.push({ propertyName, values })
      }
    });

    // number ranges
    [].forEach(propertyName => {
      if (items[propertyName]) {
        const numberFilter: { propertyName: string, from?: number, to?: number } = { propertyName };
        if (items[propertyName].values && items[propertyName].values![0]) {
          numberFilter.from = Number(items[propertyName].values![0]);
        }

        if (items[propertyName].values && items[propertyName].values![1]) {
          numberFilter.to = Number(items[propertyName].values![1]);
        }
        numberRangeFilters.push(numberFilter);
      }
    });

    return { dateRangeFilters, propertyValueFilters, numberRangeFilters };
  }

  calcTotal(data: StatisticsItem[]): StatisticsItem[] {
    if (data.length > 0) {
      const totalItem: StatisticsItem = new StatisticsItem();
      totalItem.market = "TOTAL";

      data.forEach(item => {
        totalItem.createdExtensions += item.createdExtensions;
        totalItem.approvedExtensions += item.approvedExtensions;
        totalItem.rejectedExtensions += item.rejectedExtensions;
        totalItem.expiredExtensions += item.expiredExtensions;
        totalItem.cancelledExtensions += item.cancelledExtensions;
      });

      data.push(totalItem);
    }

    return data;
  }

  fetchList = async (scrollTop: boolean = true, save: boolean = true) => {
    this.pagination = { page: 1, pageSize: 1000 };
    this.cancelSelection();
    this.loading = true;
    const list = await this.repository.fetchAllStatistics(this.pagination, this.order, this.prepareFilters(this.selectedItems));
    this.loading = false;
    this.setListAndTotal(this.calcTotal(list.list), list.total);
  };

  prepareXlsName() {
    let prefix = "export_pci";
    if (this.application === "true") {
      prefix = "export_pcifull";
    }

    if (this.application === "false") {
      prefix = "export_pcisdt";
    }

    let dates = "all-time";
    if (this.startDate && this.endDate) {
      dates = `${formatDate(this.startDate, 'ddMMyyyy')}-${formatDate(this.endDate, 'ddMMyyyy')}`
    }

    if (this.startDate && !this.endDate) {
      dates = `from-${formatDate(this.startDate, 'ddMMyyyy')}`
    }

    if (!this.startDate && this.endDate) {
      dates = `to-${formatDate(this.endDate, 'ddMMyyyy')}`
    }

    return `${prefix}-${dates}.xls`;
  }

  downloadExport = async () => {
    const response = await this.repository.downloadExport(this.prepareFilters(this.selectedItems));
    if (response) {
      downloadjs('data:application/vnd.ms-excel;base64,' + response, this.prepareXlsName());
    }
  }
}
