import BaseComponent from "@component/BaseComponent";
import Panel from "@eman/emankit/Panel";
import ServiceExtension from "@model/ServiceExtension";
import { observer } from "mobx-react";
import React from "react";
import MediaPreview from "./MediaPreview";
import ExtensionOffer from "./ExtensionOffer";
import ServiceExtensionShowVM from '@vm/Show/ServiceExtension';

interface ExtensionDetailProps {
  vm: ServiceExtensionShowVM;
  entity: ServiceExtension;
  localize: (key: string) => string;
  formatter: Intl.NumberFormat;
}

@observer
export default class ExtensionDetail extends BaseComponent<
  ExtensionDetailProps
> {
  render() {
    const { entity, localize, formatter, vm } = this.props;

    return (
      <Panel>
        <div className="row extension-detail">
          <div className="col-lg-6">
            <MediaPreview entity={entity} localize={localize} />
          </div>
          <div className="col-lg-6">
            <ExtensionOffer
              vm={vm}
              entity={entity}
              localize={localize}
              formatter={formatter}
            />
          </div>
        </div>
      </Panel>
    );
  }
}
