import Icon, { IconType } from "@eman/emankit/Icon";
import BindingElement, { BindingProps } from "@util/Form/BindingElement/index";
import ValidationManager from "@util/Form/ValidationManager";
import { observer } from "mobx-react";
import React from "react";
import "./tagsBoxStyle.scss";

@observer
export default class TagsBox extends BindingElement<BindingProps<any>, any> {
  constructor(props: any) {
    super(props);

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e: any) {
    const { entity, property, capitalize, minLength, exclude } = this.props;

    if (capitalize) {
      e.target.value = e.target.value.toUpperCase()
    }

    const targetValue = e.target.value;

    if (
      entity &&
      property &&
      targetValue.length < (minLength ? minLength : 1)
    ) {
      const propertyErrors = {};
      propertyErrors[property] = [`${minLength} characters minimum`];
      entity.appendErrors(propertyErrors);
    } else if (
      entity &&
      property &&
      targetValue.length === (minLength ? minLength : 1)
    ) {
      entity.clearErrorsForKey(property);
    }

    if (targetValue.length >= (minLength ? minLength : 1)) {
      if (entity && property) {
        entity.clearErrorsForKey(property);

        if (this.value.indexOf(targetValue) > -1 || (exclude && exclude.indexOf(targetValue) > -1)) {
          const propertyErrors = {};
          propertyErrors[property] = ["Has to be unique"];
          entity.appendErrors(propertyErrors);
          return;
        }
      }
      this.setValue([...this.value, targetValue]);
      e.target.value = "";
    }
  }

  handleRemoveItem(index: number) {
    return () => {
      this.setValue(
        this.value.filter((item: string, i: number) => i !== index)
      );
    };
  }

  // tslint:disable-next-line:member-ordering
  render() {
    const { numbers, characters, ...rest } = this.props;

    return (
      <label className="tag_container">
        <ul className="tag_ul_container">
          {this.value && Array.isArray(this.value.slice()) && this.value.map((item: string, i: number) => (
            <li
              className="tag_items"
              key={i}
              onClick={this.handleRemoveItem(i)}
              {...rest}
            >
              {item}
              <Icon
                icon={IconType.Cross}
                className="icon"
                width={10}
                height={10}
              />
            </li>
          ))}
          <input
            className="tag_input"
            onKeyPress={ValidationManager.handleInputKeyPress(
              numbers,
              characters
            )}
            onChange={this.handleInputChange}
            {...rest}
          />
        </ul>
      </label>
    );
  }
}
