import StatisticsInfo from "@model/StatisticsInfo";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import ShowViewModel from "./ShowViewModel";
import BaseRepository from '@repository/BaseRepository';

@injectable()
export default class StatisticsInfoShowVM extends ShowViewModel<
StatisticsInfo,
BaseRepository<StatisticsInfo>
> {
  constructor(
    @inject(TYPES.StatisticsInfoRepository)
    repository: BaseRepository<StatisticsInfo>
  ) {
    super(StatisticsInfo, repository);
  }

  init(id: number | string | void) {
    this.setId('');
  }
}
