import { observer } from "mobx-react";
import React from "react";

import InputContainer from "@eman/emankit/InputContainer";

import BaseModel from "@model/BaseModel";
import BindingElement, { BindingProps } from "./BindingElement";
import { fieldError } from "./index";

interface FormFieldProps<T> extends BindingProps<T> {
  label?: string;
  hint?: string;
  note?: string;
  className?: string;
  formGroup?: boolean;
  headerError?: boolean;
}

const fieldEdited = (target: BaseModel, property: string | undefined): boolean => {
  let ret = false;

  if (target && property && target.isDirty) {
    ret = target.isDirty.get(property) || false;
  }

  return ret;
};

@observer
export default class GenericFormField<TProps extends FormFieldProps<any>> extends React.Component<TProps> {
  // This is use-full for code above, we have to iterate over react child and find form items
  static fieldFormInstance = true;

  render() {
    const { target, headerError, property, errorProperty, disabled, dirty, className, id, label, required, format, labelCol, formGroup, hint } = this.props;

    const propsForChildren = { target, property, disabled, dirty };

    let forId: string;
    const boundChildren = React.Children.map(this.props.children, (child: any) => {
      if (BindingElement.isPrototypeOf(child.type)) {
        if (child.props.id && !forId) {
          forId = child.props.id;
        }
        return React.cloneElement(child, propsForChildren)
      }
      else {
        return child;
      }
    });

    const errorMessage = fieldError(target, errorProperty || property);

    return (
      <InputContainer headerError={headerError} className={className} edited={fieldEdited(target, property)} id={id} label={label} error={errorMessage} required={required} format={format || 'column'} labelCol={labelCol} formGroup={formGroup} hint={hint}>
        {boundChildren}
      </InputContainer>
    );
  };
}
