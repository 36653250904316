import Storage from '@model/Storage';
import { injectable } from 'inversify';
import BaseRepository from './BaseRepository';

@injectable()
export default class StorageRepository extends BaseRepository<Storage> {
  get classModelName(): string {
    return 'Storage';
  }

  constructor() {
    super(Storage, 'admin/storage', 'storage');
  }
}
