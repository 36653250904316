import BaseComponent from "@component/BaseComponent";
import Icon, { IconType } from "@eman/emankit/Icon";
import { observer } from "mobx-react";
import React from "react";

interface PreviewPlaceholderProps {
  localize: (key: string) => string;
}

@observer
export default class PreviewPlaceholder extends BaseComponent<
  PreviewPlaceholderProps
> {
  render() {
    const { localize } = this.props;

    return (
      <div className="media-placeholder d-flex align-items-center justify-content-center">
        <div>
          <div className="media-placeholder-icon d-flex justify-content-center">
            <Icon icon={IconType.PCIMediaPlaceholder} width={56} height={53} />
          </div>
          <div className="placeholder-label-wrapper">
            <label className="placeholder-label">
              {localize("media.placeholder.label")}
            </label>
          </div>
        </div>
      </div>
    );
  }
}
