import { observer } from "mobx-react";
import React from "react";
import { OptionsType, ValueType } from "react-select/src/types";

import SelectWithSearch, { SelectOption } from "@eman/emankit/SelectWithSearch";

import BindingElement, { BindingProps } from "./index";

@observer
export default class MultiSelectBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (values: ValueType<SelectOption>) => {
    if (values) {
      this.setValue((values as OptionsType<SelectOption>).map(item => item.value));
    } else {
      this.setValue([]);
    }
  };

  protected isGroup = (item: any): item is FormUtils.SelectGroup => {
    return item.options !== undefined;
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const { allowEmpty, options, emptyRow, dirty, onValueChanged, formatValue, parseValue, ...rest } = this.props;
    const items: SelectOption[] = [];

    if (options) {
      options.forEach((option: FormUtils.SelectGroup | FormUtils.SelectOption) => {
        if (this.isGroup(option)) {
          // @TODO Add real support for groups at SelectWithSearch component
          option.options.forEach((item: FormUtils.SelectOption) => {
            items.push({
              value: item.value,
              label: item.label,
            });
          });
        } else {
          items.push({
            value: option.value,
            label: option.label,
          });
        }
      });
    }

    const selectedValues = items.filter(optionItem => {
      return !!(this.value || []).find((valueItem: number | string) => valueItem === optionItem.value);
    });

    if (allowEmpty) {
      items.unshift({
        value: "",
        label: emptyRow || "",
      });
    }

    return (
      <SelectWithSearch
        onChange={this.handleValueChanged}
        value={selectedValues || []}
        {...rest}
        options={items}
        placeholder={emptyRow}
        isMulti={true}
      />
    );
  }
}
