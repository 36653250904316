import BaseModel from "@model/BaseModel";
import { computed, observable } from "mobx";
import isURL from "validator/lib/isURL";

export default class ServiceExtension extends BaseModel {
  @observable date: Date;
  @observable vin: string;
  @observable phone: string;
  @observable responsibleTechnician: string;
  @observable greeting: string;
  @observable status: ServiceExtensionStatus;
  @observable action: string;
  @observable approvedPrice: number;
  @observable currency: string;
  @observable offer: string;
  @observable extensionPrice: number;
  @observable offerExpiration: Date;
  @observable images: string[];
  @observable isServiceScheduled: boolean;
  @observable isVehicleSkoda: boolean;
  // @observable videoSource: string = "dummy";
  // @observable video: string =
    // "http://amssamples.streaming.mediaservices.windows.net/91492735-c523-432b-ba01-faba6c2206a2/AzureMediaServicesPromo.ism/manifest";
  @observable video: string;
  @observable videoType: string = "application/vnd.ms-sstr+xml";
  @observable patchInProgress: boolean = false;
  @observable maskedExtensionId: string;
  @observable languageCode: string;
  @observable dealerName: string;

  @computed get canUseExtensionToolBox() {
    return this.status === ServiceExtensionStatus.Pending;
  }

  @computed get canShowAcceptedOrRejectedState() {
    return (
      this.status === ServiceExtensionStatus.Approved ||
      this.status === ServiceExtensionStatus.Rejected
    );
  }

  @computed get isAccepted() {
    return this.status === ServiceExtensionStatus.Approved;
  }

  @computed get isRejected() {
    return this.status === ServiceExtensionStatus.Rejected;
  }

  @computed get isExpired() {
    return (
      this.status === ServiceExtensionStatus.PastDue
      || this.status === ServiceExtensionStatus.CanceledByService
    );
  }

  @computed get showPreviewPlaceholder() {
    return (
      this.status === ServiceExtensionStatus.Rejected ||
      (this.images.length === 0 && !this.isVideoAvailable)
    );
  }

  @computed get isVideoAvailable() {
    return this.video
    && this.videoType
    && isURL(this.video);
  }
}

export enum ServiceExtensionStatus {
  Pending,
  PastDue,
  Rejected,
  CanceledByService,
  Approved
}
