import { observable } from 'mobx';
import BaseModel from 'src/Models/BaseModel';

export enum Role {
  ADMIN = 'PORTABLECAM.ADMIN',
  HQ = 'PORTABLECAM.HQ'
}

export default abstract class BaseUser extends BaseModel {
  @observable email: string;
  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable role: Role;
}
