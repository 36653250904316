import React from 'react';
import BaseComponent from '@component/BaseComponent';
import StatisticsInfo from '../StatisticsInfo/Show';
import StatisticsList from './List';

export default class Statistics extends BaseComponent {

  render() {
    return (
      <div>
        <StatisticsInfo />
        <div className="col-lg-12">
          <StatisticsList />
        </div>
      </div>
    );
  }

}
