import BaseComponent from "@component/BaseComponent";
import { observer } from "mobx-react";
import React from "react";
import ServiceExtension from "@model/ServiceExtension";
import InfoLabel from "@component/InfoLabel";
import { formatDate } from "@util/DateFormats";

interface HeaderProps {
  entity: ServiceExtension;
  localize: (key: string) => string;
}

@observer
export default class Header extends BaseComponent<HeaderProps> {
  render() {
    const { entity, localize } = this.props;

    return (
      <React.Fragment>
        <div className="col-md-auto header-element">
          {entity.date && <InfoLabel
            label={localize("date.label")}
            value={formatDate(new Date(entity.date))}
          />}
        </div>
        <div className="col-md-auto header-element">
          <InfoLabel label={localize("vin.label")} value={entity.vin} />
        </div>
        <div className="col-md-auto header-element">
          <InfoLabel label={localize("phone.label")} value={entity.phone} />
        </div>
        <div className="col-md-auto header-element">
          <InfoLabel
            label={localize("responsible.technician.label")}
            value={entity.responsibleTechnician}
          />
        </div>
        <div className="col-md-auto header-element">
          {!!entity.maskedExtensionId && (
            <InfoLabel
              label={localize("id.label")}
              value={entity.maskedExtensionId}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
