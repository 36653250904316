import { observer } from 'mobx-react';
import React from 'react';
import uuid from 'uuid';

import Button, { ButtonVariant } from '@eman/emankit/Button';
import Modal from '@eman/emankit/Modal';

import EventBus, { SHOW_ERROR } from '@util/EventBus';
import { localize } from "@util/Localization";
import BaseComponent from '../BaseComponent';

interface ErrorBarState {
  errors: {
    [key: string]: string,
  },
}

@observer
export default class ErrorBar extends BaseComponent<{}, ErrorBarState> {
  state = {
    errors: {},
  };

  // Hook to event bus directly
  UNSAFE_componentWillMount() {
    EventBus.on(SHOW_ERROR, this.showError);
  }

  componentWillUnmount() {
    EventBus.off(SHOW_ERROR, this.showError);
  }

  // New request to show message
  showError = (message: string | ErrorMessage) => {
    const errors = this.state.errors;
    const id: string = uuid();

    let errorMessage: string | undefined;
    if (typeof message === "string") {
      errorMessage = message;
    } else if (message) {
      const translatedError = localize(message.code);
      let showError = true;

      if (message.single) {
        for (const key in errors) {
          if (errors.hasOwnProperty(key) && errors[key] === translatedError) {
            showError = false;
          }
        }
      }

      if (showError) {
        errorMessage = translatedError;
      }
    } else {
      errorMessage = localize('errors.unknown_error')
    }

    if (errorMessage) {
      this.setState({errors: {...errors, [`${id}`]: errorMessage}});
    }

  };

  render() {
    const errors = this.state.errors;
    const header = localize('errors.title');

    const errorsModals: React.ReactNode[] = [];

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const closeError = () => {
          // @ts-ignore
          const {[key]: id, ...others} = errors;
          this.setState({
            errors: {...others},
          });
        };

        const buttons = () => (
          <div style={{textAlign: 'right'}}>
            <Button onClick={closeError} variant={ButtonVariant.Primary}>OK</Button>
          </div>
        );

        errorsModals.push(
          <Modal
            key={`error-${key}`}
            header={header}
            open={true}
            onClose={closeError}
            buttons={buttons}
          >
            <div>
              {errors[key]}
            </div>
          </Modal>
        );
      }
    }

    return errorsModals;
  }
}
