import { classToPlain, plainToClass } from "@eman/class-transformer";
import EventBus, { ENTITY_CREATED, ENTITY_DELETED, ENTITY_UPDATE } from '@util/EventBus';
import { injectable, unmanaged } from 'inversify';
import { toJS } from "mobx";
import ApiClient from "../Utils/ApiClient";

@injectable()
export default abstract class BaseRepository<T extends models.Base> implements Repository<T> {

  constructor(
    @unmanaged() protected model: new () => T,
    @unmanaged() protected uri: string,
    @unmanaged() protected modelName: string
  ) {
    // Nohting to do
  }

  get classModelName(): string {
    return this.model.name;
  }

  filtersToWhereParams(filters?: FilterValues): any {
    const where = {};
    if (filters) {
      for (const compositeKey in filters) {
        if (filters.hasOwnProperty(compositeKey)) {
          const keys = compositeKey.split(',');
          let values: any = toJS(filters[compositeKey].values);
          let operator: FilterOperator = filters[compositeKey].operator;

          // Correct requests to lte or gte if only one value is set
          if (['between', 'lte', 'gte', 'lt', 'gt'].indexOf(operator) !== -1 && Array.isArray(values) && values.length === 2) {
            if (values[0] === null || values[0] === undefined) {
              operator = 'lte';
              values = values[1];
            } else if (values[1] === null || values[1] === undefined) {
              operator = 'gte';
              values = values[0];
            }
          }

          keys.forEach(key => {
            where[`where[${key}][type]`] = operator;
            where[`where[${key}][values]`] = values;
          });

          if (keys.length > 1) {
            where[`or[]`] = compositeKey;
          }
        }
      }
    }
    return where;
  }

  /**
   * Fetch items
   * @param args
   */
  fetchItems(args: FetchItemsArguments): Promise<{ items: T[] }> {
    const {loading, filters, ignoreErrors, save, id, preferencePrefix, ...rest} = args;

    const params = {
      save,
      key_prefix: preferencePrefix,
      ...this.filtersToWhereParams(filters),
      ...rest,
    };

    const config = {
      url: this.uri,
      params,
      id: id || `FETCH_DATA_${this.modelName.toUpperCase()}`,
      loading,
      ignoreErrors
    };

    return ApiClient.fetchData(config)
      .then((response) => {
        const data = {
          items: []
        };
        data.items = plainToClass(this.model, response.items) as any || [];
        return data;
      });

  }

  /**
   * Fetch data list.
   *
   * @param limit
   * @param offset
   */
  fetchList(args: FetchListArguments): Promise<{ list: T[], total: number, others: any }> {
    const {loading, pagination, save, order, filters, ignoreErrors, preferencePrefix, id, customUri, ...rest} = args;

    const params = {
      // limit: pagination.pageSize,
      // offset: pagination.page * pagination.pageSize,
      'order[field]': order ? order.field : undefined,
      'order[direction]': order ? order.direction : undefined,
      ...this.filtersToWhereParams(filters),
      // save,
      key_prefix: preferencePrefix,
      ...rest // Columns, visible columns etc...
    };

    const config = {
      url: `${this.uri}${customUri || ''}`,
      params,
      loading,
      id: id || `FETCH_LIST_${this.modelName.toUpperCase()}`,
      ignoreErrors
    };

    return ApiClient.fetchData(config)
      .then((response) => {
        const items = response.results || response;
        const data = {
          total: items.count,
          list: [],
          others: response.others || {},
          original: response,
        };
        data.list = plainToClass(this.model, items) as any;
        return data;
      });
  }

  /**
   * Autocomplete for table helpers.
   * @param value Key to fetch.
   */
  autocomplete(value: string): Promise<string[]> {
    const config = {
      url: `${this.uri}/autocomplete`,
      id: `AUTOCOMPLETE_${this.modelName.toUpperCase()}`,
      params: {
        q: value
      }
    };

    return ApiClient.fetchData(config).then((data) => data.data);
  }

  /**
   * Enqueue to export.
   *
   * @param type Format type (xlsx/csv)
   */
  startExport(type?: string): Promise<boolean> {
    const config = {
      url: 'exports',
      method: 'POST' as 'POST',
      data: {
        export: {
          format_type: type || 'csv',
          type: this.uri
        }
      },
      id: `CREATE_EXPORT_${this.modelName}`
    };

    return ApiClient.fetchResponse(config)
      .then((response) => {
        return response.status;
      });
  }

  /**
   * Create.
   *
   * @param object Object
   */
  create(object: T): Promise<ApiResponse<T>> {
    const config = {
      url: this.uri,
      method: 'POST' as 'POST',
      id: `CREATING_${this.modelName.toUpperCase()}`,
      data: {
        [this.modelName]: classToPlain(object)
      }
    };

    return ApiClient.fetchResponse(config)
      .then((response) => {
        if (response.original) {
          EventBus.trigger(ENTITY_CREATED, {
            identificator: this.classModelName,
            id: response.original.id
          });
        }

        return response;
      });
  }

  /**
   * Get data.
   *
   * @param id
   */
  show(id: number | string, hasParent: boolean = false, loading: boolean = true, ): Promise<ApiResponse<T>> {
    const identifier = hasParent ? '' : `/${id}`

    const config = {
      url: `${this.uri}${identifier}`,
      method: 'GET' as 'GET',
      id: `GET_${this.modelName}`,
      loading
    };

    return ApiClient.fetchResponse(config)
      .then((response) => {
        if (response.original && response.status) {
          response.entity = plainToClass(this.model, response.original) as any;
        }

        return response;
      });
  }

  /**
   * Destroy
   *
   * @param object Object
   */
  destroy(id: number | string | T): Promise<boolean> {
    const config = {
      url: `${this.uri}/${id}`,
      method: 'DELETE' as 'DELETE',
      id: `DELETE_${this.modelName}`
    };

    return ApiClient.fetchResponse(config)
      .then((response) => {

        if (response.status) {
          EventBus.trigger(ENTITY_DELETED, {
            identificator: this.classModelName,
            id
          });
        }

        return response.status;
      });
  }

  /**
   * Update.
   *
   * @param id object id
   * @param object Object data
   */
  update(id: number | string | null, object: any, params: any = {}) {
    const config = {
      url: `${this.uri}${id ? '/' + id : ''}`,
      method: 'PUT' as 'PUT',
      id: `UPDATING_${this.modelName.toUpperCase()}`,
      params,
      data: {
        [this.modelName]: classToPlain(object)
      }
    };

    return ApiClient.fetchResponse(config)
      .then(response => {
        EventBus.trigger(ENTITY_UPDATE, {
          identificator: this.classModelName,
          id
        });

        return response;
      });
  }

}
