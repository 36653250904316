import Show from "@component/Show";
import 'chartjs-plugin-datalabels';

import { observer } from "mobx-react";
import React from "react";
import { lazyInject, TYPES } from "src/inversify.config";
import { ChartData, ChartOptions } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import StorageShowVM from '@vm/Show/Storage';
import Storage from '@model/Storage';
import Panel from "@eman/emankit/Panel";
import InfoLabel from "@component/InfoLabel";

import "./styles.scss";

const _round = (value: number) => value ? Math.round(value * 10) / 10 : 0;
const calcPercent = (value: number, total: number) => (value / total) * 100;
const formatPercent = (v: string | number, hideMin15?: boolean) => (hideMin15 && v < 15) ? '' : `${v || 0}%`;

const DATA_MAP = [
  { label: "photos", key: 'photos', color: "#72c5ed", textColor: 'white' },
  { label: "videos", key: 'videos', color: "#0090d6", textColor: 'white' },
];

const CHART_OPTIONS: ChartOptions = {
  responsive: false,
  legend: { display: false },
  plugins: {
    datalabels: {
      color: DATA_MAP.map(item => item.textColor),
      formatter: (v) => formatPercent(v, true),
    }
  }
};

const BAR_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 25,
        callback: (v) => formatPercent(v)
      },
      gridLines: {
        drawBorder: false,
      },
    }]
  },
  plugins: {
    datalabels: {
      color: 'white',
      formatter: (v) => formatPercent(v)
    }
  }
};

interface StorageShowState {
  barWidth: number,
  pieWidth: number,
  firstDraw: boolean,
}

@observer
export default class StorageShow extends Show<Storage, StorageShowVM, {}, StorageShowState> {
  modelName = "storage";

  @lazyInject(TYPES.StorageShow)
  vm: StorageShowVM;

  state = {
    barWidth: 0,
    pieWidth: 0,
    firstDraw: false,
  }

  getDoughnutData(media: any): ChartData {
    return ({
      labels: DATA_MAP.map(item => this.ta(item.label)),
      datasets: [
        {
          data: DATA_MAP.map(item => media[item.key] || 0),
          backgroundColor: DATA_MAP.map(item => item.color),
        },
      ],
    });
  }

  formatGiB(key: string, showPercents: boolean = true) {
    const { storageUsage } = this.vm.entity;
    const { totalStorageCapacityGiB } = storageUsage;
    const value = storageUsage ? _round(storageUsage[key]) : 0;
    let percent = "";
    if (showPercents) {
      percent = `(${_round(calcPercent(value, totalStorageCapacityGiB))}%)`;
    }
    return `${value} GB ${percent}`;
  }

  getBarData(): ChartData {
    const { usageHistory, storageUsage } = this.vm.entity;

    if (usageHistory) {
      return ({
        labels: usageHistory.map(item => item.month + " " + item.year),
        datasets: [{
          data: usageHistory.map(item => _round(calcPercent(item.mediaSizeGiB, storageUsage.totalStorageCapacityGiB))),
          backgroundColor: 'rgba(21, 175, 151, 0.85)',
          minBarLength: 15,
        }]
      });
    }

    return ({});
  };

  convertMedia() {
    const { media } = this.vm.entity;
    const total = media.withPhotos + media.withVideo;

    return {
      photos: _round(calcPercent(media.withPhotos, total)),
      videos: _round(calcPercent(media.withVideo, total)),
    };
  }

  render() {
    if (this.vm.currentlyFetching) {
      return null;
    }

    const mediaPercents = this.convertMedia();

    return (
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-6">
          <div className="charts-title">
            <span>{"Storage usage".toUpperCase()}</span>
          </div>
          <Panel>
            <div className="charts-wrapper">
              <div className="legend">
                <InfoLabel label={this.ta("capacity")} value={this.formatGiB('totalStorageCapacityGiB', false)} />
                <br />
                <InfoLabel label={this.ta("used")} value={this.formatGiB('usedStorageGiB')} />
                <br />
                <InfoLabel label={this.ta("free")} value={this.formatGiB('availableStorageCapacityGiB')} />
                <br />
                <br />
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6">
          <div className="charts-title">
            <span>{"Type of media".toUpperCase()}</span>
          </div>
          <Panel>
            <div className="charts-wrapper">
              <div>
                <Pie height={200} data={this.getDoughnutData(mediaPercents)} options={CHART_OPTIONS} />
                <div className="pie-legend">
                  <InfoLabel label={this.ta("photos")} value={`(${formatPercent(mediaPercents.photos)})`} markColor={DATA_MAP[0].color} />
                  <br />
                  <InfoLabel label={this.ta("videos")} value={`(${formatPercent(mediaPercents.videos)})`} markColor={DATA_MAP[1].color} />
                </div>
              </div>
            </div>
          </Panel>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="charts-title">
            <span>{"Usage history".toUpperCase()}</span>
          </div>
          <Panel className="bar-panel">
            <div className="charts-wrapper">
              <Bar data={this.getBarData()} options={BAR_OPTIONS} />
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}
