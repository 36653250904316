import Icon, { IconType } from "@eman/emankit/Icon";
import { observer } from "mobx-react";
import React from "react";
import Select, { components } from 'react-select';
import BindingElement, { BindingProps } from "./index";
import SelectOption = FormUtils.SelectOption;
import './selectBoxStyle.scss'

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    color: '#4d545a',
    padding: '5px 10px',
    backgroundColor: 'white',
    '&:hover': {
      color: '#4ba82e',
      backgroundColor: 'white',
      cursor: 'pointer'
    },
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    minHeight: '38px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    'div': {
      padding: 0,
    },
    '&:focus': {
      border: 'none'
    }
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: 'none',
    'svg': {
      fill: 'none !important'
    }
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#4d545a'
  }),
  menu: (provided: any) => ({
    ...provided,
    'z-index': '999 !important',
    borderRadius: 0,
    boxShadow: '0 15px 30px 0 rgba(0, 0, 0, 0.1)'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    color: '#4d545a'
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: '#4d545a',
    padding: '5px 8px !important',
    border: 'solid 1px #cfd6dc',
    backgroundColor: 'white',
    'div': {
      fontSize: '14px !important',
    },
    'div:hover': {
      backgroundColor: 'white',
      color: '#4d545a',
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    color: '#4ba82e'
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    backgroundColor: 'none',
    color: '#4d545a',
  }),
  container: (provided: any) => ({
    ...provided,
    borderBottom: '1px solid #4d545a',
  })
};

// tslint:disable-next-line:variable-name
const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon className="form-select-indicator" icon={props.selectProps.menuIsOpen ? IconType.SoconArrowUp : IconType.SoconArrowDown} width={20}
              height={8}/>
      </components.DropdownIndicator>
    )
  );
};

// tslint:disable-next-line:variable-name
const CrossIcon = (props: any) => {
  return (
    components.CrossIcon && (
      <components.CrossIcon {...props}>
        <Icon icon={IconType.Cross}/>
      </components.CrossIcon>
    )
  )
};

// tslint:disable-next-line:variable-name
const MultiValueRemove = (props: any) => {
  return (
    components.MultiValueRemove && (
      <components.MultiValueRemove {...props}>
        <Icon style={{marginLeft: '4px'}} icon={IconType.Cross} width={10} height={10}/>
      </components.MultiValueRemove>
    )
  )
};

// tslint:disable-next-line:variable-name
const ClearIndicator = (props: any) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <span/>
      </components.ClearIndicator>
    )
  )
};


@observer
export default class SelectBox extends BindingElement<BindingProps<any>, any> {

  protected handleValueChanged = (value: SelectOption | SelectOption[]) => {
    if (Array.isArray(value)) {
      this.setValue(value.map(option => option.value))
    } else {
      if (value) {
        this.setValue(value.value)
      } else {
        this.setValue([])
      }
    }
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const {options, isMulti, emptyRow} = this.props;

    return (
      <Select
        value={options ? options.filter(option => this.value instanceof Array
          ? this.value.indexOf(option.value) > -1
          : option.value === this.value) : null}
        components={{DropdownIndicator, MultiValueRemove, ClearIndicator, CrossIcon}}
        styles={customStyles}
        onChange={this.handleValueChanged}
        placeholder={emptyRow}
        isMulti={isMulti}
        options={options}
        className="form-select"
      />
    );
  }
}
