import Extension from '@model/Extension';
import { injectable } from 'inversify';
import BaseRepository from './BaseRepository';
import ApiClient from '@util/ApiClient';
import { plainToClass } from '@eman/class-transformer';

@injectable()
export default class ExtensionRepository extends BaseRepository<Extension> {
  get classModelName(): string {
    return 'Extension';
  }

  constructor() {
    super(Extension, 'admin/extensions', 'storage');
  }

  fetchAllExtensions(pagination: Pagination, order: OrderOptions, filters: any): Promise<{ list: Extension[], total: number, others: any }>  {
    const config = {
      url: `${this.uri}/search`,
      method: 'POST' as 'POST',
      id: 'FETCH_ALL_EXTENSIONS',
      data: {
        page: pagination.page,
        pageSize: pagination.pageSize,
        sort: {
          propertyName: order.field,
          sortOrder: order.direction === 'asc' ? 1 : 2
        },
        ...filters
      }
    }

    return ApiClient.fetchData(config)
      .then((response) => {
        const items = response.results || response;
        const data = {
          total: response.totalResults || 0,
          list: plainToClass(this.model, items || []) as any[],
          others: response.others || {},
          original: response,
        };
        return data;
      });
  }
}
