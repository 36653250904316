export interface ValidationOptions {
  required?: boolean
  requiredMessage?: string
  minLength?: number
  formatMessage?: string
  asArray?: boolean
  disabled?: boolean
}

export default class ValidationManager {

  static formValidation(entity: models.Base, options: { [key: string]: ValidationOptions }) {
    Object.keys(options).forEach(property => {
      if (entity[property] instanceof Array && options[property].asArray) {
        entity[property].forEach((item: models.Base) => {
          Object.keys(item.validationOptions).forEach(nestedProperty => {
            this.validate(item, nestedProperty, item.validationOptions[nestedProperty])(item[nestedProperty])
          });
        });
      } else {
        this.validate(entity, property, options[property])(entity[property])
      }
    });
  };

  static handleInputKeyPress = (numbers: boolean | undefined, characters: boolean | undefined) => (e: any) => {
    const code = (e.which) ? e.which : e.keyCode;
    if (numbers && !characters && code > 31 && (code < 48 || code > 57)) {
      e.preventDefault()
    }

    if (numbers && characters && code < 48 && code > 57 && code < 65 && code > 90) {
      e.preventDefault()
    }
  };

  static validate = (entity: models.Base, property: string, option: ValidationOptions) => (value: string) => {

    entity.clearErrorsForKey(property);
    if (option.disabled) {
      return
    }

    const errors = Array<string>();
    if (option.required && (!value || value && value.length === 0)) {
      errors.push(option.requiredMessage
        ? option.requiredMessage!
        : 'Required')
    }

    const minLength = option.minLength;
    if (minLength && (!value || value.length < minLength)) {
      errors.push(option.formatMessage || `${minLength} characters minimum`)
    }

    if (errors.length === 0) {
      entity.clearErrorsForKey(property);
      return
    }

    const propertyErrors = {};
    propertyErrors[property] = errors;
    entity.appendErrors(propertyErrors)
  }

  static isNormalInteger(text: string) {
    const inputNumber = Math.floor(Number(text));
    return inputNumber !== Infinity && String(inputNumber) === text && inputNumber >= 0;
  }
}
