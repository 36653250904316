import "reflect-metadata";

// Polyfills
import 'react-app-polyfill/ie11';
import 'airbnb-js-shims';

// Import DI to set it (user etc..)
import { container, TYPES } from './inversify.config';

// tslint:disable-next-line:ordered-imports
import Raven from 'raven-js';
import React from 'react';
import ReactDOM from 'react-dom';
import EventBus, { SCROLL_TOP } from './Utils/EventBus';

// Default app container
import Router from './Router/index';

// Import theme from UIkit
import '@eman/emankit/index.css';
import UIKitLocaleProvider from "@eman/emankit/LocaleProvider";
import { localize } from "@util/Localization";

import './styles.scss';
import { unregister } from "./registerServiceWorker";

unregister();

// Set UI KIT basic data.
UIKitLocaleProvider.changeLanguage('en', localize('UIKit'));

// Start raven
if (process.env.SENTRY_DSN && process.env.CI_COMMIT_SHA) {
  Raven.config(process.env.SENTRY_DSN, {
    release: process.env.CI_COMMIT_SHA,
  }).install();
}

const location = window.location
export const B2B_CONNECTION = `${process.env.REACT_APP_B2B_BASE}account/external-login?callback=${location.protocol + '//' + location.host + location.pathname}%23/callback`;

// Bind scroll top event
EventBus.on(SCROLL_TOP, () => {
  window.scrollTo(0, 0);
});

// TODO: Try to fetch default values from containers
// container.get<Services.CurrentUser>(TYPES.User).fetchUser();

// Debug only
// tslint:disable-next-line:no-string-literal
window['container'] = container;
// tslint:disable-next-line:no-string-literal
window['types'] = TYPES;

// TODO: If we use inject in Router we have problem, that compiler fails on required arguments.
ReactDOM.render(
  <React.Fragment>
    <Router />
  </React.Fragment>,
  document.getElementById('root') as HTMLElement
);
