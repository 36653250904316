import { get } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import CheckBoxInput from "@eman/emankit/Input/CheckBox";

import BindingElement, { BindingProps } from "./index";

@observer
export default class CheckBoxArray extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = () => {
    // Get original array
    const { target, property } = this.props as BindingProps<any>
    const array: any[] = (get(target, property) as string[]) ? (get(target, property) as string[]) : [].slice(0);

    if (!this.props.id) {
      return;
    }

    const index = array.indexOf(this.props.id);

    // Remove or add?
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(this.props.id);
    }

    this.setValue(array);
  };

  protected get value() {
    const { target, property } = this.props as BindingProps<any>;

    if (!target) {
      throw new Error("'target' prop has not been set");
    }

    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    if (!this.props.id) {
      throw new Error("'id' prop is required for CheckBoxArray!");
    }

    return get(target, property).indexOf(this.props.id) >= 0;
  }

  // tslint:disable-next-line:member-ordering
  render() {
    return (
      <CheckBoxInput value={this.value} onChange={this.handleValueChanged} {...this.props} />
    );
  }
}
