import UriHelper from '@util/UriHelper';

export const EXPORT_MAX_RECORDS = 2000;

export const routes = {
  extension: {
    key: 'extension_id',
  },
  statistics: {
    key: 'statistics_id',
    children: {
      market: {
        key: "market_id",
      },
    },
  },
};

export const uriHelper = new UriHelper(routes as any) as any;
