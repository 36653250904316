import BaseModel from "@model/BaseModel";
import StorageUsage from "@model/StorageUsage";
import Media from "@model/Media";
import UsageHistoryItem from "@model/UsageHistoryItem";

export default class Storage extends BaseModel {
  storageUsage: StorageUsage = new StorageUsage();
  media: Media = new Media();
  usageHistory: UsageHistoryItem[] = [];
}
