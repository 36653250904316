import { inject, injectable, postConstruct } from "inversify";

import Extension from "@model/Extension";
import ListViewModel from "@vm/List/ListViewModel";

import TYPES from "../../inversify.types";
import { action } from 'mobx';

@injectable()
export default class ExtensionListVM extends ListViewModel<Extension, ExtensionRepository<Extension>> {
  static defaults = {
    order: {
      field: 'createdDateTime',
      direction: 'desc',
    },
    columns: [],
    visibleFilters: []
  };

  constructor(
    @inject(TYPES.ExtensionRepository) repository: ExtensionRepository<Extension>
  ) {
    super(repository);
  }

  @postConstruct()
  listenEventBus() {
    //
  }

  @action.bound
  cancelSelection() {
    this.setSelectedRows([]);
  }


  prepareFilters(items: FilterValues) {
    const dateRangeFilters: Array<{ propertyName: string, from?: string, to?: string }> = [];
    const propertyValueFilters: Array<{ propertyName: string, values: any[] }> = [];
    const numberRangeFilters: Array<{ propertyName: string, from?: number, to?: number }> = [];

    // dates
    if (items && items.createdDateTime) {
      const dateFilter: { propertyName: string, from?: string, to?: string } = { propertyName: "createdDateTime" };
      if (items.createdDateTime.values && items.createdDateTime.values[0]) {
        dateFilter.from = items.createdDateTime.values[0];
      }

      if (items.createdDateTime.values && items.createdDateTime.values[1]) {
        dateFilter.to = items.createdDateTime.values[1];
      }
      dateRangeFilters.push(dateFilter);
    }

    // values
    ["maskedExtensionId", "status", "countryCode", "dealerId", "mediaType"].forEach(propertyName => {
      if (items[propertyName] && items[propertyName].values) {
        let values: any[] = [];
        if (typeof items[propertyName].values === "object") {
          values = items[propertyName].values as any[];
        } else {
          values.push(items[propertyName].values);
        }
        propertyValueFilters.push({ propertyName, values })
      }
    });

    // number ranges
    ["mediaSizeMiB", "extensionAge"].forEach(propertyName => {
      if (items[propertyName]) {
        const numberFilter: { propertyName: string, from?: number, to?: number } = { propertyName };
        if (items[propertyName].values && items[propertyName].values![0]) {
          numberFilter.from = Number(items[propertyName].values![0]);
        }

        if (items[propertyName].values && items[propertyName].values![1]) {
          numberFilter.to = Number(items[propertyName].values![1]);
        }
        numberRangeFilters.push(numberFilter);
      }
    });

    return { dateRangeFilters, propertyValueFilters, numberRangeFilters };
  }


  fetchList = async (scrollTop: boolean = true, save: boolean = true) => {
    this.cancelSelection();
    this.loading = true;
    const list = await this.repository.fetchAllExtensions(this.pagination, this.order, this.prepareFilters(this.selectedItems));
    this.loading = false;
    this.setListAndTotal(list.list, list.total);
  };

  @action.bound
  async deleteSelected() {
    if (this.selectedRows.length > 0) {
      Promise.all(this.selectedRows.map(async (item) => {
        await this.repository.destroy(item.extensionId);
      })).then(() => {
        this.fetchList();
      });
    }
  }

  @action.bound
  async deleteExtension(item: Extension) {
    await this.repository.destroy(item.extensionId);
    this.fetchList();
  }

}
