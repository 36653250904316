import { observer } from "mobx-react";
import React from "react";
import { ValueType } from 'react-select/src/types';

import SelectWithSearch, { SelectOption } from "@eman/emankit/SelectWithSearch";

import BindingElement, { BindingProps } from "./index";

@observer
export default class SelectWithSearchBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (value: ValueType<SelectOption>) => {
    this.setValue((value as SelectOption).value);
  };

  protected isGroup = (item: any): item is FormUtils.SelectGroup => {
    return item.label !== undefined;
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const { options, emptyRow, dirty, onValueChanged, formatValue, parseValue, ...rest } = this.props;
    const items: SelectOption[] = [];

    if (options) {
      options.forEach((option: FormUtils.SelectGroup | FormUtils.SelectOption) => {
        if (this.isGroup(option)) {
          // @TODO Add real support for groups at SelectWithSearch component
          option.options.forEach((item: FormUtils.SelectOption) => {
            items.push({
              value: item.value,
              label: item.label,
            });
          });
        } else {
          items.push({
            value: option.value,
            label: option.label,
          });
        }
      });
    }

    const selectedValue = items.find(item => {
      return item.value === this.value;
    });
    return (
      <SelectWithSearch onChange={this.handleValueChanged} value={selectedValue || null} {...rest} options={items} placeholder={emptyRow} />
    );
  }
}
