import { inject, injectable } from "inversify";

import User from "@model/User";
import ListViewModel from "@vm/List/ListViewModel";

import TYPES from "../../inversify.types";

@injectable()
export default class UserListVM extends ListViewModel<User, Repository<User>> {
  static defaults = {
    order: {
      field: 'last_name',
      direction: 'asc',
    },
    columns: [
      'first_name', 'last_name', 'email'
    ],
    visibleFilters: [
      'first_name', 'last_name', 'email'
    ]
  };

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>
  ) {
    super(repository);
  }

}
