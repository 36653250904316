import React from "react";
import BaseList from "@component/BaseList";
// import { FilterBarProps } from "@eman/emankit/FilterBar";
import ExtensionListVM from "@vm/List/Extension";
import { observer } from "mobx-react";
import { lazyInject, TYPES } from "../../inversify.config";

import Extension, { MediaType } from "@model/Extension";
import { HeaderProps } from "@eman/emankit/DataTable/HeaderBar";

import { formatDate } from "@util/DateFormats";
import { ServiceExtensionStatus } from "@model/ServiceExtension";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import Icon, { IconType } from "@eman/emankit/Icon";

import "./styles.scss";

@observer
export default class ExtensionList extends BaseList<
  Extension,
  ExtensionListVM,
  {},
  {}
> {
  modelName = "storage";
  searchable = false;
  emptyRow = this.ta("emptyRow");

  @lazyInject(TYPES.ExtensionList)
  vm: ExtensionListVM;

  constructor(props: any) {
    super(props);

    this.state = {
      filterOpen: false,
      columnsOpen: false,
      loading: true,
      filters: {},
    };
  }

  headerProps(): HeaderProps | undefined {
    return undefined;
  }

  renderStatus(status: ServiceExtensionStatus) {
    let labelKey;
    let iconType;
    let color = "";
    switch (status) {
      case ServiceExtensionStatus.Approved:
        labelKey = "status.approved";
        iconType = IconType.SoconApprove;
        color = "#15af97";
        break;
      case ServiceExtensionStatus.CanceledByService:
        labelKey = "status.canceledByService";
        iconType = IconType.SoconPause;
        color = "#e62336";
        break;
      case ServiceExtensionStatus.PastDue:
        labelKey = "status.pastDue";
        iconType = IconType.SoconExpired;
        color = "#ffdf43";
        break;
      case ServiceExtensionStatus.Pending:
        labelKey = "status.pending";
        iconType = IconType.SoconAwait;
        color = "#0090d7";
        break;
      case ServiceExtensionStatus.Rejected:
        labelKey = "status.rejected";
        iconType = IconType.SoconReject;
        color = "#e62336";
        break;
      default:
        return null;
    }

    return this.renderBadge(labelKey, iconType, color);
  }

  renderLink(item: Extension) {
    return (
      <a href={item.extensionUrl} target="_blank">
        {item.humanId}
      </a>
    );
  }

  renderMediaType(mediaType: MediaType) {
    let labelKey;
    let iconType;
    switch (mediaType) {
      case MediaType.Photos:
        labelKey = "mediaType.photos";
        iconType = IconType.PCIMediaTypePhotos;
        break;
      case MediaType.Videos:
        labelKey = "mediaType.videos";
        iconType = IconType.PCIMediaTypeVideos;
        break;
      default:
        labelKey = "mediaType.none";
        iconType = IconType.PCIMediaTypeNone;
        break;
    }

    return this.renderBadge(labelKey, iconType);
  }

  renderBadge(labelKey?: string, iconType?: IconType, color?: string) {
    if (!labelKey || !iconType) {
      return null;
    }

    return (
      <span style={{ color }}>
        <Icon icon={iconType} width={24} height={24} />
        &nbsp;{this.ta(labelKey)}
      </span>
    );
  }

  renderDelete(item: Extension) {
    if (this.vm.selectedRows.length > 0) {
      return null;
    }

    return (
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Icon
          className="delete-icon"
          width={24}
          height={24}
          icon={IconType.PCIBin}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => this.vm.deleteExtension(item)}
        />
      </div>
    );
  }

  renderRow = (item: Extension, column: string) => {
    switch (column) {
      case "maskedExtensionId":
        return this.renderLink(item);
      case "status":
        return this.renderStatus(item.status);
      case "createdDateTime":
        return formatDate(item.createdDateTime);
      case "mediaSizeMiB":
        return `${Math.round(item.mediaSizeMiB * 100) / 100 || 0} MB`;
      case "mediaType":
        return this.renderMediaType(item.mediaType);
      case "delete":
        return this.renderDelete(item);
      default:
        return item[column];
    }
  };

  paginationProps() {
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange,
      label: "results",
    };
  }

  renderBottom() {
    if (this.vm.selectedRows.length === 0) {
      return null;
    }

    return (
      <div
        style={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          zIndex: 9999,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "64px",
            padding: "0px 2em",
          }}
        >
          <div>
            <b>Selected {this.vm.selectedRows.length}</b>&nbsp;
            <a onClick={this.vm.cancelSelection}>Cancel selection</a>
          </div>
          <Button
            variant={ButtonVariant.Dangerous}
            icon={IconType.PCIBin}
            iconWidth={24}
            iconHeight={24}
            onClick={this.vm.deleteSelected}
          >
            Delete ({this.vm.selectedRows.length})
          </Button>
        </div>
      </div>
    );
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "maskedExtensionId",
        type: "search",
        placeholder: "Search",
      },
      {
        id: "status",
        type: "values",
        values: [
          {
            id: ServiceExtensionStatus.Approved,
            name: this.ta("status.approved"),
          },
          {
            id: ServiceExtensionStatus.CanceledByService,
            name: this.ta("status.canceledByService"),
          },
          {
            id: ServiceExtensionStatus.PastDue,
            name: this.ta("status.pastDue"),
          },
          {
            id: ServiceExtensionStatus.Pending,
            name: this.ta("status.pending"),
          },
          {
            id: ServiceExtensionStatus.Rejected,
            name: this.ta("status.rejected"),
          },
        ],
      },
      {
        id: "createdDateTime",
        type: "date_range",
      },
      {
        id: "extensionAge",
        type: "range",
        min: 0,
      },
      {
        id: "countryCode",
        type: "search",
        placeholder: "Search",
      },
      {
        id: "dealerId",
        type: "search",
        placeholder: "Search",
      },
      {
        id: "mediaSizeMiB",
        type: "range",
        min: 0,
      },
      {
        id: "mediaType",
        type: "values",
        values: [
          { id: MediaType.Photos, name: this.ta("mediaType.photos") },
          { id: MediaType.Videos, name: this.ta("mediaType.videos") },
          { id: MediaType.None, name: this.ta("mediaType.none") },
        ],
      },
    ];

    return {
      hideFilterSettings: true,
      hideColumnSettings: true,
      hideReset: true,
      filters,
    };
  }

  dataProps() {
    const columns = [
      this.createField("maskedExtensionId", {
        tooltip: true,
        label: this.ta("extensionId"),
        width: 140,
      }),
      this.createField("status", { width: 120, tooltip: true }),
      this.createField("createdDateTime", { width: 120, tooltip: true }),
      this.createField("extensionAge", { width: 120, tooltip: true }),
      this.createField("countryCode", { width: 120, tooltip: true }),
      this.createField("dealerId", { width: 100, tooltip: true }),
      this.createField("mediaSizeMiB", { width: 100, tooltip: true }),
      this.createField("mediaType", { width: 100, tooltip: true }),
      this.createField("delete", {
        width: 60,
        tooltip: false,
        nosort: true,
        hideLabel: true,
      }),
    ];

    if (this.vm.columns.length === 0) {
      this.vm.setColumns(columns.map((column) => column.id));
    }

    return {
      columns,
      value: this.renderRow,
      loading: false,
      fixedHeader: true,
    };
  }
}
