import { plainToClass } from '@eman/class-transformer';
import { injectable } from 'inversify';

import CurrentUser from '@model/CurrentUser';
import ApiClient from '../Utils/ApiClient';
import BaseRepository from './BaseRepository';

@injectable()
export default class CurrentUserRepository extends BaseRepository<CurrentUser> implements AbstractCurrentUserRepository<CurrentUser> {
  get classModelName(): string {
    return 'CurrentUser';
  }
  
  constructor() {
    super(CurrentUser, 'users', 'user');
  }

  fetchCurrent(loading: boolean): Promise<ApiResponse<CurrentUser>> {
    const config = {
      url: 'session/current',
      id: 'FETCH_USER',
      loading
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (response.status && response.original) {
        response.entity = plainToClass(CurrentUser, response.original.entity);
      }

      return response;
    });
  }

  login(): Promise<ApiResponse<CurrentUser>> {
    const config = {
      method: 'GET' as 'GET',
      url: 'account/external-login',
      id: 'LOGIN_USER',
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (!response.status && response.original.data) {
        // In login case we have different structure of errors, we have to
        // transform it for ViewModel
        if (response.original.data.errors) {
          response.errors = response.original.data.errors;
        } else if (response.original.data.error) {
          response.errors = {
            username: response.original.data.error
          };
        }
      }

      if (response.status && response.original && response.original.entity) {
        response.entity = plainToClass(CurrentUser, response.original.entity);
      }

      return response;
    });
  }

  logout(): Promise<ApiResponse<CurrentUser>> {
    const config = {
      method: 'DELETE' as 'DELETE',
      url: 'session',
      id: 'LOGOUT_USER'
    };

    return ApiClient.fetchResponse(config);
  }

  update(username: string, user: CurrentUser): Promise<ApiResponse<CurrentUser>> {
    const config = {
      url: `user`,
      method: 'PUT' as 'PUT',
      id: `UPDATING_${this.modelName}`,
      data: {
        username,
        user
      }
    };

    return ApiClient.fetchResponse(config);
  }
}
