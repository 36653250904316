import BaseModel from "@model/BaseModel";

export default class StatisticsItem extends BaseModel {
  market: string = "";
  dealerId: number = 0;
  createdExtensions: number = 0;
  approvedExtensions: number = 0;
  rejectedExtensions: number = 0;
  expiredExtensions: number = 0;
  cancelledExtensions: number = 0;
}
