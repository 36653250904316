import React from 'react';
import { Switch } from 'react-router';
import { NavLink, Redirect, Route } from 'react-router-dom';

// General base components
import BaseComponent from '@component/BaseComponent';
import PageRoute from "@component/PageRoute";
import Icon, { IconType } from '@eman/emankit/Icon';
import VerticalMenu from '@eman/emankit/VerticalMenu';
import { localize } from "@util/Localization";

export interface SidebarLink {
  path: string;
  title: string;
}

export default abstract class RouterBase<TProps = {}> extends BaseComponent<TProps> {
  /**
   * Generate redirect component to given path
   *
   * @param path string
   */
  redirect(pathFunction: (match: any) => string) {
    return (match: any) => (
      <Redirect to={pathFunction(match.match.params)} />
    );
  }

  /**
   * Generate breadcrumb title.
   * @param code
   */
  breadcrumbTitle(code: string) {
    return localize(`breadcrumb.${code}`);
  }

  /**
   * Generate sidebar links.
   *
   * @param links
   * @param backLink
   */
  sidebarLinks(links: SidebarLink[], backLink?: string) {
    return (
      <>
        <div className="mb-20 mt-20">
          <NavLink to={backLink || this.uriHelper.dashboard()}><Icon icon={IconType.ArrowBack} /></NavLink>
        </div>
        <VerticalMenu links={links.map(link => {
          return (
            <NavLink
              key={link.path}
              to={link.path}
              activeClassName={'active'}
            >
              {link.title}
            </NavLink>
          );
        })}
        />
      </>
    );
  }

  /**
   * Renders recursively AppRoutes
   *
   * In one switch `component` or `render` function
   * In separately switch renders modalComponent
   *
   */
  pageRouteRender = (pageRoutes: AppRoute[]) => {
    return (
      <>
        <Switch>
          {pageRoutes.map((route) => {
            let renderFunction;
            const { redirect, component, render } = route;


            if (render !== undefined) {
              renderFunction = render;
            } else if (redirect !== undefined) {
              renderFunction = this.redirect(redirect);
            }

            if (renderFunction === undefined && component === undefined) {
              return null;
            }

            return (
              <PageRoute
                key={route.path.toString()}
                path={route.path}
                title={route.title}
                exact={route.exact}
                render={renderFunction}
                component={component}
              />
            );

          })}
        </Switch>
        <Switch>
          {pageRoutes.map((route) => {
            const modalComponent = route.modalComponent;

            if (!modalComponent) {
              return null;
            }

            return (
              <Route
                key={`${route.path}-modal`}
                path={route.path}
                exact={route.exact}
                render={route.render}
                component={modalComponent}
              />
            );
          })}
        </Switch>
      </>
    );
  };
}
