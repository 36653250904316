import BaseComponent from "@component/BaseComponent";
import ServiceExtension from "@model/ServiceExtension";
import { observer } from "mobx-react";
import React from "react";
import PreviewPlaceholder from "./PreviewPlaceholder";
import AzureMediaPlayer from "./AzureMediaPlayer";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {
  MOUSE_ACTIVATION,
  PictureInPictureMagnifier,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

interface MediaPreviewProps {
  entity: ServiceExtension;
  localize: (key: string) => string;
}

@observer
export default class MediaPreview extends BaseComponent<MediaPreviewProps> {
  myRenderItem(item: ReactImageGalleryItem) {
    return (
      <PictureInPictureMagnifier
        imageSrc={item.original}
        largeImageSrc={item.thumbnail}
        mouseActivation={MOUSE_ACTIVATION.DOUBLE_TAP}
        touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
      />
    );
  }

  render() {
    const { localize, entity } = this.props;

    return (
      <div className="media-preview-wrapper">
        {entity && entity.showPreviewPlaceholder ? (
          <PreviewPlaceholder localize={localize} />
        ) : entity.isVideoAvailable ? (
          <AzureMediaPlayer entity={entity} />
        ) : (
          <ImageGallery
            items={entity.images.map((image) => ({
              original: image,
              thumbnail: image,
            }))}
            showNav={true}
            showFullscreenButton={true}
            showPlayButton={false}
            renderItem={this.myRenderItem}
            infinite={false}
          />
        )}
      </div>
    );
  }
}
