import React from 'react';
import BaseComponent from '@component/BaseComponent';
// @ts-ignore
import { Document, Page } from 'react-pdf';

import './Licence.css';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
  // https://stackoverflow.com/questions/73333451/getting-error-when-displaying-a-pdf-file-using-react-pdf
  options: {workerSrc: "pdf.worker.js"}
};
export default class Licence extends BaseComponent {

  state = {
    file: '/portablecam-licence-agreement.pdf',
    numPages: null,
    pageNumber: 1
  }

  constructor(props: any) {
    super(props);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  onDocumentLoadSuccess({numPages: nextNumPages}: any) {
    this.setState({
      numPages: nextNumPages,
      pageNumber: 1
    });
  }

  previousPage() {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  nextPage() {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  render() {
    return (
      <div className="licence-wrapper">
        <Document file={this.state.file} onLoadSuccess={this.onDocumentLoadSuccess} options={options}
                  loading="Načítání dokumentu...">
          <Page pageNumber={this.state.pageNumber}/>
        </Document>
        {this.state.numPages !== null &&
          <div className="licence-page-controls">
            <button
              type="button"
              disabled={this.state.pageNumber <= 1}
              onClick={this.previousPage}>‹
            </button>
            <span>{this.state.pageNumber || (this.state.numPages ? 1 : '--')} / {this.state.numPages || '--'}</span>
            <button
              type="button"
              disabled={this.state.pageNumber >= (this.state.numPages ?? 0)}
              onClick={this.nextPage}>›
            </button>
          </div>
        }
      </div>
    );
  }

}

