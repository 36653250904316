import BaseModel from "@model/BaseModel";
import { ServiceExtensionStatus } from '@model/ServiceExtension';

export enum MediaType {
  None,
  Photos,
  Videos
};

export default class Extension extends BaseModel {
  countryCode: string;
  createdDateTime: Date;
  dealerId: string;
  extensionAge: string;
  extensionId: string;
  // extensionUrl: string;
  mediaSizeMiB: number;
  status: ServiceExtensionStatus;
  mediaType: MediaType;
  maskedExtensionId: string;

  get humanId() {
    return !!this.maskedExtensionId ? this.maskedExtensionId : this.extensionId;
  }

  get extensionUrl() {
    return `/#/extension/${this.extensionId}/detail`;
  }
}
