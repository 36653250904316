const LOCALIZATIONS = {

  'project.title': 'ŠKODA Service center',
  'footer': 'Copyright ©ŠKODA Auto a.s. 2020. All rights reserved - [www.skoda-auto.com](https://www.skoda-auto.com/)',
  'footerLicence': 'License Conditions',

  'uploader': {
    text: 'test',
    or: 'test2',
    button: 'Browse',
  },

  'filters': {
    search: 'Search',
    all: 'All',
    selectAll: 'Select All',
    selectNothing: 'Select Nothing',
    confirm: 'Confirm',
    foundResults: 'Found Results',
    empty: 'Empty',
    loadingRow: 'Loading...',
    editFilters: 'Edit filters',
    resetFilters: 'Clear filters',
    resetFilter: 'Clear filter',
    from: 'from',
    to: 'to',
  },

  'UIKit': {
    date_picker: {
      strings: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdaysLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        firstDayOfWeek: 0,
        todayButton: 'Today',
        fromDate: 'From',
        toDate: 'To',
      },
      format: 'dd. MM. yyyy',
    },
    pagination: {
      next: 'Next',
      previous: 'Previous',
      showed: 'Records per page',
      from: 'From',
      records: 'records',
    },
    datatable: {
      display: {
        newDisplay: 'New',
        inputName: 'Input name',
        save: 'save',
        cancel: 'Cancel',
        deleteMessage: 'Delete message',
        notSelected: 'Not selected',
        saveDisplay: 'Save',
        removeDisplay: 'Remove',
        defaultGroup: 'Default group',
        usersGroup: 'Users group',
      },
      filters: {
        search: 'Search',
        all: 'All',
        selectAll: 'Select All',
        selectNothing: 'Select Nothing',
        confirm: 'Confirm',
        foundResults: 'Found Results',
        empty: 'Choose',
        editFilters: 'Edit filters',
        resetFilters: 'Clear filters',
        resetFilter: 'Clear filter',
        from: 'from',
        to: 'to',
      },
      emptyRow: 'Empty',
      export: {
        title: 'Export',
        body: 'Body',
        confirm: 'Confirm',
      },
      editColumns: 'Columns',
      openstate: {
        opened: 'Opened',
        closed: 'Closed',
      },
    },
    confirmation: {
      yes: 'Yes',
      no: 'No',
    },
    autocomplete: {
      headerText: 'Header',
      deleteSuggestionsText: 'Delete suggestions',
    },
    input_container: {
      optional_text: 'Optional',
      required_text: '',
    },
    address_picker: {
      right_selected_address: 'Right address',
    },
    multiselect: {
      search: 'Search',
      selectValues: 'Select values',
      selected: 'Selected',
      empty: 'Empty',
    },
    select: {
      select: 'Select',
      empty: 'Empty',
    },
  },

  'errors.title': 'Problem occurred',
  'errors.unknown_error': 'Please check, your connection or contact system administrator for more information.',

  'service.extension.show.found.new.defect': 'there has been found a new deffect on your car',
  'service.extension.show.date.label': 'Date',
  'service.extension.show.vin.label': 'VIN',
  'service.extension.show.phone.label': 'Phone',
  'service.extension.show.responsible.technician.label': 'Responsible technician',
  'service.extension.show.id.label': 'ID',
  'service.extension.show.approved.price.label': 'Approved price:',
  'service.extension.show.greeting.label': 'Dear {0},',
  'service.extension.show.extension.offer.label': 'SERVICE EXTENSION OFFER',
  'service.extension.show.price.of.extension.label': 'Price of extension',
  'service.extension.show.total.price.label': 'Total price',
  'service.extension.show.date.till.offer.stands.label': 'Date till offer stands',
  'service.extension.show.media.placeholder.label': 'Video / photos are no longer available',
  'service.extension.show.expiration.note': 'Please note that this is a time limited offer and it will expire {0}.',
  'service.extension.show.extension.accepted': 'EXTENSION ACCEPTED',
  'service.extension.show.extension.rejected': 'EXTENSION REJECTED',
  'service.extension.show.extension.reject': 'REJECT EXTENSION',
  'service.extension.show.extension.accept': 'ACCEPT EXTENSION',
  'service.extension.show.extension.expired.label': 'Extension has expired',
  'service.extension.show.more.info.label': '[For more information ...](https://www.skoda-auto.com/)',
  'service.extension.show.already.scheduled': 'ALREADY SCHEDULED',

  'storage.capacity': 'Capacity',
  'storage.used': 'Used',
  'storage.free': 'Free',

  'storage.photos': 'Photos',
  'storage.videos': 'Videos',
  'storage.none': 'No media',

  'storage.extensionId': 'ID',
  'storage.status': 'Status',
  'storage.createdDateTime': 'Created',
  'storage.extensionAge': 'Age',
  'storage.countryCode': 'Market',
  'storage.dealerId': 'Dealer',
  'storage.mediaSizeMiB': 'Size',
  'storage.mediaType': 'Media',

  'storage.mediaType.none': 'None',
  'storage.mediaType.photos': 'Photos',
  'storage.mediaType.videos': 'Videos',

  'storage.status.approved': 'Approved',
  'storage.status.canceledByService': 'Canceled by service',
  'storage.status.pastDue': 'Past due',
  'storage.status.pending': 'Pending',
  'storage.status.rejected': 'Rejected',
  'storage.emptyRow': 'No extensions found.',

  'statistics.market': 'Market',
  'statistics.dealerId': 'Dealer',
  'statistics.createdExtensions': 'Created',
  'statistics.approvedExtensions': 'Approved',
  'statistics.rejectedExtensions': 'Rejected',
  'statistics.expiredExtensions': 'Expired',
  'statistics.cancelledExtensions': 'Cancelled',

  'statistics.filter.application': 'Application',
  'statistics.filter.application.option.pcifull': 'PCI Full',
  'statistics.filter.application.option.pcisdt': 'PCI SDT',
  'statistics.filter.application.option.all': 'All',
  'statistics.filter.application.placeholder': 'Select',
  'statistics.filter.startDate': 'Start date',
  'statistics.filter.startDate.placeholder': 'All time',
  'statistics.filter.endDate': 'End date',
  'statistics.filter.endDate.placeholder': 'All time',
  'statistics.filter.export': 'Export',
  'statistics.emptyRow': 'No statistics found.',

  'statisticsinfo.status.approved': 'Approved',
  'statisticsinfo.status.rejected': 'Rejected',
  'statisticsinfo.status.expired': 'Expired',
  'statisticsinfo.status.cancelled': 'Cancelled',

  'market.market': 'Market',
  'market.dealerId': 'Dealer',
  'market.createdExtensions': 'Created',
  'market.approvedExtensions': 'Approved',
  'market.rejectedExtensions': 'Rejected',
  'market.expiredExtensions': 'Expired',
  'market.cancelledExtensions': 'Cancelled',

  'market.filter.back': 'Back',
  'market.filter.application': 'Application',
  'market.filter.application.option.pcifull': 'PCI Full',
  'market.filter.application.option.pcisdt': 'PCI SDT',
  'market.filter.application.option.all': 'All',
  'market.filter.application.placeholder': 'Select',
  'market.filter.startDate': 'Start date',
  'market.filter.startDate.placeholder': 'From',
  'market.filter.endDate': 'End date',
  'market.filter.endDate.placeholder': 'To',
  'market.filter.export': 'Export',
  'market.emptyRow': 'No results found.',
};

export default LOCALIZATIONS;
