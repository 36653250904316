import React from 'react';
import BaseComponent from '@component/BaseComponent';

import StorageShow from '@view/Extension/Show';
import ExtensionsList from '@view/Extension/List';

export default class StoragePage extends BaseComponent {


  render() {
    return (
      <div>
        <StorageShow />
        <div className="col-lg-12">
          <ExtensionsList />
        </div>
      </div>
    );
  }

}
