import { inject, injectable } from 'inversify';

import User from '@model/User';
import CreateViewModel from '@vm/Create/CreateViewModel';
import TYPES from '../../inversify.types';

@injectable()
export default class UserCreateVM extends CreateViewModel<User, Repository<User>> {

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>,
  ) {
    super(User, repository);
  }

}
