import Show from "@component/Show";
import ProgressIndicator from '@eman/emankit/ProgressIndicator';
import ServiceExtension from "@model/ServiceExtension";
import ServiceExtensionShowVM from "@vm/Show/ServiceExtension";
import { observer } from "mobx-react";
import React from "react";
import { lazyInject, TYPES } from "src/inversify.config";
import ExpiredExtension from "./ExpiredExtension";
import ExtensionBasicInfo from "./ExtensionBasicInfo";
import ExtensionDetail from "./ExtensionDetail";
import Header from "./Header";
import "./styles.scss";

@observer
export default class ServiceExtensionShow extends Show<
  ServiceExtension,
  ServiceExtensionShowVM
> {
  modelName = "service.extension.show";

  @lazyInject(TYPES.ServiceExtensionShow)
  vm: ServiceExtensionShowVM;

  render() {
    const { entity, externalLocs } = this.vm;

    if (externalLocs && !this.user.isLoggedIn) {
      this.externalLocs = externalLocs;
      if (entity && entity.dealerName) {
        this.user.setApplicationName(entity.dealerName);
      }
    }

    const formatter = new Intl.NumberFormat("cs-CS", {
      minimumFractionDigits: 0,
    });

    return (
      <React.Fragment>
        {entity.vin ? <React.Fragment>
          {entity.isExpired ? (
            <ExpiredExtension localize={this.ta} />
          ) : (
            <div className="container service-extension">
              <div className="row extension-header">
                <Header entity={entity} localize={this.ta} />
              </div>
              <ExtensionBasicInfo
                entity={entity}
                localize={this.ta}
                formatter={formatter}
              />
              <ExtensionDetail
                vm={this.vm}
                entity={entity}
                localize={this.ta}
                formatter={formatter}
              />
            </div>
          )}
        </React.Fragment> : this.vm.currentlyFetching && <ProgressIndicator/>}
      </React.Fragment>
    );
  }
}
