import React, { PureComponent } from 'react';

import Toast from '@eman/emankit/Toast';

import EventBus, { SHOW_TOAST } from '@util/EventBus';

interface ToastBarState {
  visible: boolean;
  message?: string;
}

export default class ToastBar extends PureComponent<{}, ToastBarState> {
  static DEFAULT_INTERVAL = 5;

  private currentTimeout?: number;

  constructor(props: any) {
    super(props);

    this.state = {
      visible: false,
      message: undefined
    };
  }

  // Hook to event bus directly
  UNSAFE_componentWillMount() {
    EventBus.on(SHOW_TOAST, this.showToast);
  }

  componentWillUnmount() {
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }
    EventBus.off(SHOW_TOAST, this.showToast);
  }

  // New request to show message
  showToast = (message: string) => {
    this.setState({
      visible: (message !== undefined && message.length > 0),
      message
    }, this.planHide)
  };

  // Timeout functions
  planHide = () => {
    // On multiple actions we want to stay "visible"
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = window.setTimeout(
      this.hide,
      ToastBar.DEFAULT_INTERVAL * 1000
    );
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };


  render() {
    return (
      <Toast visible={this.state.visible}>
        {this.state.message}
      </Toast>
    );
  }
}
