import StatisticsItem from "@model/StatisticsItem";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import ShowViewModel from "./ShowViewModel";
import BaseRepository from '@repository/BaseRepository';
import { action } from 'mobx';

@injectable()
export default class StatisticsInfoShowVM extends ShowViewModel<
StatisticsItem,
BaseRepository<StatisticsItem>
> {
  constructor(
    @inject(TYPES.StatisticsInfoRepository)
    repository: BaseRepository<StatisticsItem>
  ) {
    super(StatisticsItem, repository);
  }

  init(id: number | string | void) {
    if (id && this.id !== id) {
      this.setIdWithoutFetching(id);
    }
  }

  @action
  setIdWithoutFetching(id: number | string) {
    this.id = id;
  }
}
