import Show from "@component/Show";
import 'chartjs-plugin-datalabels';

import { observer } from "mobx-react";
import React from "react";
import StatisticsShowVM from '@vm/Show/Statistics';
import StatisticsItem from '@model/StatisticsItem';
import MarketList from '@view/Admin/Market/List';


import "../../Extension/styles.scss";
import { lazyInject, TYPES } from 'src/inversify.config';

@observer
export default class StatisticsShow extends Show<StatisticsItem, StatisticsShowVM> {
  modelName = "statistics";

  @lazyInject(TYPES.StatisticsShow)
  vm: StatisticsShowVM;

  render() {

    if (!this.vm.id) {
      return null;
    }

    return (
      <div>
        <MarketList />
      </div>
    );
  }
}
