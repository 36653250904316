import React, { Component } from 'react';

import ProgressIndicator from '@eman/emankit/ProgressIndicator';
import EventBus, { HIDE_LOADING } from "@util/EventBus";

interface ProgressBarState {
  loadingActions: string[];
}

export default class ProgressBar extends Component<{}, ProgressBarState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loadingActions: []
    };
  }

  // Hook to event bus directly
  componentDidMount() {
    // EventBus.on(SHOW_LOADING, this.showLoading);
    EventBus.on(HIDE_LOADING, this.hideLoading);
  }

  componentWillUnmount() {
    // EventBus.off(SHOW_LOADING, this.showLoading);
    EventBus.off(HIDE_LOADING, this.hideLoading);
  }

  showLoading = (key: string) => {
    if (!key || key.length === 0) {
      // tslint:disable-next-line:no-console
      console.warn(`Key for show loading can not be empty!`);
    } else {
      const newState = this.state.loadingActions;
      newState.push(key);

      this.setState({
        loadingActions: newState
      });
    }
  };

  hideLoading = (key: string) => {
    const oldState = this.state.loadingActions;
    const index = oldState.indexOf(key);

    if (index < 0) {
      // tslint:disable-next-line:no-console
      // console.warn(`Cannot found key ${key} to hide loading`);
    } else {
      oldState.splice(index, 1);

      this.setState({
        loadingActions: oldState
      });
    }
  };

  render() {
    if (this.state.loadingActions.length > 0) {
      return (<ProgressIndicator/>);
    } else {
      return null;
    }
  }
}
