import { observer } from "mobx-react";
import React from "react";

import DatePicker, { formatDate } from "@eman/emankit/DatePicker";

import BindingElement, { BindingProps } from "./index";
import { DEFAULT_DATE_FORMAT } from '@util/DateFormats';

@observer
export default class DatePickerBox extends BindingElement<BindingProps<any>, any> {
  
  protected handleValueChanged = (formattedDate: string, date: Date) => {
    this.setValue(date);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    return (
      <DatePicker {...this.props} onChange={this.handleValueChanged} value={this.value ? formatDate(new Date(this.value), DEFAULT_DATE_FORMAT) : ''} hideOnDayClick={true} placeholder={this.props.placeholder}/>
    );
  }
}
