export type HandlerFunction = (...args: any[]) => void;

// UI notifications
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const START_REQUEST = 'START_REQUEST';
export const STOP_REQUEST = 'STOP_REQUEST';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_TOAST = 'SHOW_TOAST';

export const ENTITY_UPDATE = 'ENTITY_UPDATED';
export const ENTITY_CREATED = 'ENTITY_CREATED';
export const ENTITY_DELETED = 'ENTITY_DELETED';

// File upload
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';

// Scrolling events
export const SCROLL_TOP = 'SCROLL_TOP';
export const SCROLL_TO_FIRST_ERROR = 'SCROLL_TO_FIRST_ERROR';

// Locale
export const LOCALE_CHANGE = 'LOCALE_CHANGE';

// Force load notifications
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

// User events
export const LOGIN_USER = 'LOGIN_USER';
export const REFRESH_USER_FILTER_SETTINGS = 'REFRESH_USER_FILTER_SETTINGS';
export const LOGOUT_USER = 'LOGOUT_USER';

export class EventBus {
  // Turn on-off debug mode
  static DEBUG_MODE = process.env.NODE_ENV === 'development';

  private handlers: { [event: string]: HandlerFunction[] } = {};

  /**
   * Register handler.
   *
   * @param event Event name
   * @param handler Handler
   */
  on(event: string, handler: HandlerFunction): void {
    if (!this.hasEvent(event)) {
      this.handlers[event] = [];
    }

    if (EventBus.DEBUG_MODE) {
      // tslint:disable-next-line:no-console
      console.log(`-> EventBus - add handler for ${event}`);
    }

    this.handlers[event].push(handler);
  }

  /**
   * Trigger event with data object.
   *
   * @param event Event name
   * @param data Any data
   */
  trigger(event: string, data?: any) {
    if (EventBus.DEBUG_MODE) {
      // tslint:disable-next-line:no-console
      console.log(`-> EventBus - trigger handler for ${event} ${JSON.stringify(data)}`);
    }

    if (this.hasEvent(event)) {
      this.handlers[event].forEach((fct) => {
        fct(data);
      });
    }
  }

  /**
   * Remove event handler.
   *
   * @param event Event name
   * @param handler Handler
   */
  off(event: string, handler: HandlerFunction): void {
    if (EventBus.DEBUG_MODE) {
      // tslint:disable-next-line:no-console
      console.log(`-> EventBus - remove handler for ${event}`);
    }

    if (this.hasEvent(event)) {
      const arr = this.handlers[event];
      const index = arr.indexOf(handler);

      if (index < 0) {
        // tslint:disable-next-line:no-console
        console.warn(`-> EventBus - remove handler for ${event} - fails because handler does not exists`);
      } else {
        this.handlers[event].splice(index, 1);
      }
    } else {
      // tslint:disable-next-line:no-console
      console.warn(`-> EventBus - remove handler for ${event} - fails because event does not exists`);
    }
  }

  /**
   * Is event already defined?
   *
   * @param event Event name
   * @return Boolean
   */
  private hasEvent(event: string): boolean {
    return (event in this.handlers);
  }
}

export default new EventBus();
