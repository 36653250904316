import BaseComponent from "@component/BaseComponent";
import Panel from "@eman/emankit/Panel";
import Icon, { IconType } from "@eman/emankit/Icon";
import { observer } from "mobx-react";
import React from "react";

interface ExpiredExtensionProps {
  localize: (key: string) => string;
}

@observer
export default class ExpiredExtension extends BaseComponent<
  ExpiredExtensionProps
> {
  render() {
    const { localize } = this.props

    return (
      <div className="container d-flex justify-content-center expired-extension">
        <Panel className="expired-extension-panel">
          <br />
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <div className="d-flex justify-content-center expired-icon">
                <Icon
                  className="expired-extension-icon"
                  icon={IconType.PCIExpired}
                  width={56}
                  height={56}
                />
              </div>
              <div className="extension-expired-label-wrapper">
                <label className="extension-expired-label">
                  {localize("extension.expired.label")}
                </label>
              </div>
            </div>
          </div>
          <br />
        </Panel>
      </div>
    );
  }
}
