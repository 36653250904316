import {
  classToPlain,
  Exclude,
  plainToClass,
  Type
} from "@eman/class-transformer";
import "core-js/es7/reflect";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { action, computed, observable } from "mobx";

import { ValidationOptions } from "@util/Form/ValidationManager";

export const NORMALIZED_DATE_FORMAT = "y-M-d";

export default abstract class BaseModel implements models.Base {
  /**
   * Return number of changes.
   *
   * @return number
   */
  @computed
  get changes(): number {
    let changes = 0;

    if (this.isDirty) {
      this.isDirty.forEach(value => {
        if (value === true) {
          changes += 1;
        }
      });
    }

    this.isDirtyRelations().forEach(element => {
      if (this[element]) {
        changes += this[element].changes;
      }
    });

    return changes;
  }

  /**
   * Error count.
   *
   * @return number
   */
  @computed
  get errorCount(): number {
    let errors = 0;

    if (this.errors) {
      this.errors.forEach(value => {
        if (value && ((value.length && value.length > 0) || value !== "")) {
          errors += 1;
        }
      });
    }

    this.isDirtyRelations().forEach(element => {
      if (this[element]) {
        errors += this[element].errorCount;
      }
    });

    return errors;
  }

  /**
   * Determine if record is new record.
   *
   * @returns Boolean
   * @memberof BaseModel
   */
  @computed get newRecord(): boolean {
    return this.id === undefined;
  }

  /**
   * Generate string version of ID (for filters).
   *
   * @readonly
   * @type {string}
   * @memberof BaseModel
   */
  @computed get stringId(): string | undefined {
    return this.id ? String(this.id) : undefined;
  }

  // tslint:disable-next-line:variable-name
  static __tracableModelInstance: boolean = true;

  static parseDate = (value: string): Date | undefined => {
    const parsed = parse(value, NORMALIZED_DATE_FORMAT, new Date());

    if (isValid(parsed)) {
      return parsed;
    } else {
      return undefined;
    }
  };

  static formatDate = (value: Date | undefined) => {
    if (value && isValid(value)) {
      return format(value, NORMALIZED_DATE_FORMAT);
    } else {
      return undefined;
    }
  };

  static parseNumber = (value: string): number | undefined => {
    let numberValue: number | undefined = parseFloat(value);

    if (value === null || value === undefined || value === "") {
      numberValue = undefined;
    } else if (isNaN(numberValue)) {
      numberValue = parseFloat(value.replace(",", "."));
    }

    return numberValue;
  };

  static parseBoolean = (value: string | number | boolean): boolean => {
    return value === "1" || value === "true" || value === 1 || value === true;
  };

  static formatBoolean = (value: boolean): string => {
    return value ? "1" : "0";
  };

  static parseType<TType>(value: any, type: new () => TType) {
    if (value === null || value === undefined) {
      return new type();
    } else {
      return plainToClass(type, value);
    }
  }

  static formatType(value: BaseModel) {
    return classToPlain(value);
  }

  @Exclude({ toPlainOnly: true })
  validationOptions: { [key: string]: ValidationOptions };

  // tslint:disable:variable-name
  @Exclude()
  @observable
  __alreadyAssignedEnums: boolean = false;

  @observable
  id: string;

  @Exclude()
  @observable
  saved: boolean = false;

  @Exclude()
  @observable
  markedForDestroy: boolean = false;

  @Exclude()
  @observable
  errors = observable.map();

  @Exclude()
  @observable
  oldValues: { [key: string]: any } = {};

  @Exclude()
  @observable
  isDirty = observable.map();

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @observable
  created_at: Date;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @observable
  updated_at: Date;

  /**
   * Override to observe relations for dirty changes!
   */
  isDirtyRelations(): string[] {
    return [];
  }

  /**
   * Clear all errors.
   */
  @action.bound
  clearErrors() {
    this.errors.clear();
    this.isDirty.clear();
  }

  /**
   * Clear errors for single property
   * @param key - property identifier
   */
  @action.bound
  clearErrorsForKey(key: string) {
    if (this.errors.has(key)) {
      this.errors.delete(key);
    }
  }

  /**
   * Append errors.
   * @param errors Errors
   */
  @action.bound
  appendErrors(errors: models.Errors) {
    this.updateErrors(errors);
  }

  /**
   * Set errors.
   * @param errors Errors
   */
  @action.bound
  setErrors(errors: models.Errors) {
    this.errors.clear();

    this.updateErrors(errors);
  }

  /**
   * Test if error is set.
   * @param key Key
   * @return boolean
   */
  hasError(key: string): boolean {
    return this.errors.hasOwnProperty(key);
  }

  private updateErrors(errors: models.Errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const value = errors[key];

        if (key.indexOf(".") > 0) {
          const [collection, subkey] = key.split(".", 2);

          if (this[collection] && this[collection][0]) {
            // 1:M collections
            this[collection][0].errors.set(subkey, value);
          } else if (this[collection]) {
            // 1:1 collections
            this[collection].errors.set(subkey, value);
          } else {
            // if "collection" doesn't exist on Model, use "base" key
            const base = this.errors.get("base") || [];
            base.push(value);
            this.errors.set("base", base);
          }
        } else {
          // "base" and other attribute's error messages
          this.errors.set(key, value);
        }
      }
    }
  }
}
