import BaseComponent from "@component/BaseComponent";
import ServiceExtension from "@model/ServiceExtension";
import { observer } from "mobx-react";
import React from "react";
import { AzureMP } from "react-azure-mp";

interface AzureMediaPlayerProps {
  entity: ServiceExtension;
}

@observer
export default class AzureMediaPlayer extends BaseComponent<
  AzureMediaPlayerProps
> {
  render() {
    const { entity } = this.props;

    return (
      <AzureMP
        skin="amp-flush"
        src={[
          {
            src: entity.video,
            type: entity.videoType,
          },
        ]}
      />
    );
  }
}
