import BaseModel from "@model/BaseModel";
import { observable } from 'mobx';

// TODO: model of User from B2B
export default class User extends BaseModel {

  // tslint:disable:variable-name
  @observable authenticated: boolean;

  @observable email: string;
  @observable first_name: string;
  @observable last_name: string;

}
