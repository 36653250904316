import React, { ReactNode } from 'react';

import Layout from '@eman/emankit/Layout';

import ErrorBar from "../ErrorBar";
import Footer from "../Footer";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import ToastBar from "../ToastBar";

export interface LayoutProps {
  children: ReactNode;
}

const layout = (props: LayoutProps) => {
  return (
    <Layout
      before={
        <>
          <Header />
        </>
      }
      after={
        <>
          <ToastBar />
          <ErrorBar />
          <ProgressBar />
        </>
      }
    >
      {props.children}
      <Footer />
    </Layout>
  );
};

export default layout;
