import BaseComponent from "@component/BaseComponent";
import ServiceExtension from "@model/ServiceExtension";
import { observer } from "mobx-react";
import React from "react";

interface ExtensionOfferHeaderProps {
  entity: ServiceExtension;
  localize: (key: string) => string;
}

@observer
export default class ExtensionOfferHeader extends BaseComponent<ExtensionOfferHeaderProps> {
  render() {
    const { localize, entity } = this.props;

    return (
      <div className="d-flex flex-wrap justify-content-between extension-offer-header">
        <label className="offer-label">
          {localize("extension.offer.label")}
        </label>
        {entity.canShowAcceptedOrRejectedState && (
          <div
            className={
              entity.isAccepted
                ? "extension-accepted-wrapper"
                : "extension-rejected-wrapper"
            }
          >
            <label
              className={
                entity.isAccepted ? "extension-accepted" : "extension-rejected"
              }
            >
              {localize(
                entity.isAccepted ? "extension.accepted" : "extension.rejected"
              )}
            </label>
          </div>
        )}
      </div>
    );
  }
}
