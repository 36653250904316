import { observable } from 'mobx';

import BaseUser from "@model/BaseUser";

export enum UserRightsObjects {
  EMPLOYEE = 'employee',
  EMPLOYER = 'employer',
  ENUMERATION = 'enumeration',
  ROLE = 'role',
}

export enum UserRights {
  LIST = "index",
  CREATE = "create",
  SHOW = "show",
  EDIT = "edit",
  DELETE = "delete",
}

export default class CurrentUser extends BaseUser {
  @observable authenticated: boolean = true;
}
