import BaseComponent from "@component/BaseComponent";
import Button, { ButtonIconPosition, ButtonVariant } from "@eman/emankit/Button";
import { IconType } from "@eman/emankit/Icon";
import ProgressIndicator from "@eman/emankit/ProgressIndicator";
import ServiceExtension from "@model/ServiceExtension";
import { EXTENSION_EXPIRATION_DATE_FORMAT, formatDate } from "@util/DateFormats";
import ServiceExtensionShowVM from "@vm/Show/ServiceExtension";
import { observer } from "mobx-react";
import React from "react";
import { String } from "typescript-string-operations";

interface ExtensionToolBoxProps {
  vm: ServiceExtensionShowVM;
  entity: ServiceExtension;
  localize: (key: string) => string;
}

@observer
export default class ExtensionToolBox extends BaseComponent<
ExtensionToolBoxProps
> {

  get isSkoda() {

    if (this.user.isLoggedIn) {
      return true;
    } else if (!!this.props.entity && !this.props.entity.isVehicleSkoda) {
      return false;
    }

    return true;
  }

  render() {
    const { localize, entity } = this.props;

    return (
      <React.Fragment>
        {entity.patchInProgress && <ProgressIndicator />}
        <div className="row extension-tool-box">
          <div className="col-md-6 pl-0 reject-button">
            <Button
              iconPosition={ButtonIconPosition.Left}
              icon={IconType.PCIReject}
              iconHeight={16}
              iconWidth={16}
              onClick={this.reject}
              variant={ButtonVariant.SecondaryWhite}
            >
              {localize("extension.reject")}
            </Button>
          </div>
          <div className="col-md-6 pr-0 accept-button">
            <Button
              iconPosition={ButtonIconPosition.Left}
              icon={IconType.PCIAccept}
              iconHeight={16}
              iconWidth={16}
              onClick={this.accept}
              variant={this.isSkoda ? ButtonVariant.Active : ButtonVariant.ActiveBlue}
            >
              {localize("extension.accept")}
            </Button>
          </div>
        </div>
        {entity.offerExpiration && (
          <label className="expiration-note">
            {String.Format(
              localize("expiration.note"),
              formatDate(
                entity.offerExpiration,
                EXTENSION_EXPIRATION_DATE_FORMAT
              )
            )}
          </label>
        )}
      </React.Fragment>
    );
  }

  reject = () => {
    const { vm, entity } = this.props;

    if (entity.patchInProgress) {
      return;
    }

    entity.patchInProgress = true;

    vm.rejectServiceExtension();
  };

  accept = () => {
    const { vm, entity } = this.props;

    if (entity.patchInProgress) {
      return;
    }

    entity.patchInProgress = true;

    vm.acceptServiceExtension();
  };
}
