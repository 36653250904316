import StatisticsInfo from '@model/StatisticsInfo';
import { injectable } from 'inversify';
import BaseRepository from './BaseRepository';

@injectable()
export default class StatisticsInfoRepository extends BaseRepository<StatisticsInfo> {
  get classModelName(): string {
    return 'StatisticsInfo';
  }

  constructor() {
    super(StatisticsInfo, 'admin/statistics', 'statistics');
  }
}
