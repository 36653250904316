import BaseComponent from "@component/BaseComponent";
import ServiceExtension from "@model/ServiceExtension";
import {
  EXTENSION_EXPIRATION_DATE_FORMAT,
  formatDate,
} from "@util/DateFormats";
import { observer } from "mobx-react";
import React from "react";
import ExtensionOfferHeader from "./ExtensionOfferHeader";
import ExtensionToolBox from "./ExtensionToolBox";
import ServiceExtensionShowVM from '@vm/Show/ServiceExtension';

interface ExtensionOfferProps {
  vm: ServiceExtensionShowVM;
  entity: ServiceExtension;
  localize: (key: string) => string;
  formatter: Intl.NumberFormat;
}

@observer
export default class ExtensionOffer extends BaseComponent<ExtensionOfferProps> {

  format(entity: ServiceExtension, formatter: Intl.NumberFormat) {
    return `${formatter.format(entity.extensionPrice)} ${entity.currency}`;
  }

  formatTotal(entity: ServiceExtension, formatter: Intl.NumberFormat) {
    return `${formatter.format(entity.extensionPrice + entity.approvedPrice)} ${entity.currency}`;
  }

  render() {
    const { localize, entity, formatter, vm } = this.props;

    return (
      <React.Fragment>
        <ExtensionOfferHeader entity={entity} localize={localize} />
        <div className="separator" />
        <label className="offer-description">{entity.offer}</label>
        <div className="d-flex justify-content-between price-of-extension">
          <h6>{localize("price.of.extension.label")}</h6>
          <h6>
            {this.format(entity, formatter)}
          </h6>
        </div>
        {entity.offerExpiration && <div className="d-flex flex-wrap justify-content-between date-till-offer-stands">
          <h6 className="date-till-offer-stands-label">{localize("date.till.offer.stands.label")}</h6>
          <h6>
            {formatDate(
              entity.offerExpiration,
              EXTENSION_EXPIRATION_DATE_FORMAT
            )}
          </h6>
        </div>}
        <div className="d-flex justify-content-between price-of-extension">
          <h6>{localize("show.total.price.label")}</h6>
          <h6>
            {this.formatTotal(entity, formatter)}
          </h6>
        </div>
        {entity.canUseExtensionToolBox && <ExtensionToolBox vm={vm} entity={entity} localize={localize} />}
      </React.Fragment>
    );
  }
}
