import { observer } from "mobx-react";
import React from "react";

import Collapsible from "@eman/emankit/Collapsible";

import { fieldError } from "./index";


interface FormCollapsibleProps {
  title: string;
  children: React.ReactNode;
  open?: boolean;
  buttons?: (state: any) => React.ReactNode;
  loading?: boolean;
}

@observer
export default class FormCollapsible extends React.Component<FormCollapsibleProps> {
  // tslint:disable-next-line:cognitive-complexity
  render() {
    // TODO: I don't like this code
    const testForError = (children: any): boolean => {
      let ret = false;

      if (Array.isArray(children)) {
        for (const key of children) {
          if (key && key.type && key.type.fieldFormInstance) {
            if (fieldError(key.props.target, key.props.property)) {
              ret = true;
              break;
            }
          } else if (key &&  key.props && key.props.children && testForError(key.props.children)) {
            ret = true;
            break;
          }
        }
      } else {
        if (children.type && children.type.fieldFormInstance) {
          if (fieldError(children.props.target, children.props.property)) {
            ret = true;
          }
        } else if (children.props && children.props.children && testForError(children.props.children)) {
          ret = true;
        }
      }

      return ret;
    };

    let hasError = false;
    if (this.props.children) {
      hasError = testForError(this.props.children);
    }

    return (
      <Collapsible title={this.props.title} error={hasError} buttons={this.props.buttons} defaultOpen={this.props.open} loading={this.props.loading}>
        {this.props.children}
      </Collapsible>
    )
  }
}
