import BaseRouter from './Base';
import React from "react";
import { uriHelper } from '../config';
import ServiceExtensionShow from '@view/ServiceExtension/Show';

export const serviceExtensionRoutes: AppRoute[] = [
  {
    path: uriHelper.detail_extension_uri(),
    exact: false,
    component: ServiceExtensionShow,
  }
];

export default class Campaigns extends BaseRouter {
  render() {

    return (
      <div className="emankit__layout__content">
        {this.pageRouteRender(serviceExtensionRoutes)}
      </div>
    )
  }
}