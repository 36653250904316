import { action, computed, observable } from 'mobx';

import EventBus, { LOGIN_USER } from '@util/EventBus';

import TYPES from '../inversify.types';

import CurrentUser from '@model/CurrentUser';
import { inject, injectable } from 'inversify';
import jwt_decode from 'jwt-decode';
import { B2B_CONNECTION } from "../index";

import Cookies from "js-cookie";

/**
 * General user view.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default class User implements Services.CurrentUser {

  private FIRST_NAME_KEY = 'givenName';
  private LAST_NAME_KEY = 'sn';
  private EMAIL_KEY = 'mail';
  private ROLE_KEY = 'role';
  private UID_KEY = 'uid';

  // tslint:disable-next-line: member-ordering
  @observable applicationName = "";

  // Notification data
  // tslint:disable-next-line:member-ordering
  @observable notifications: Notification[] = [];

  // tslint:disable-next-line:member-ordering
  @observable entity: CurrentUser;

  // Repositories
  private repository: AbstractCurrentUserRepository<CurrentUser>;

  constructor(
    @inject(TYPES.CurrentUserRepository) repository: AbstractCurrentUserRepository<CurrentUser>,
  ) {
    this.repository = repository;

    this.entity = new CurrentUser();
  }

  @computed
  get isLoggedIn(): boolean {
    return Cookies.get('PCI_TOKEN') !== undefined;
  }

  @action.bound
  decodeUserFromToken = () => {
    const token = Cookies.get('PCI_TOKEN');

    if (token) {
      const decoded: any = jwt_decode(token);
      const keys = Object.keys(decoded);

      const firstNameKey = keys.find(key => key.includes(this.FIRST_NAME_KEY));
      const lastNameKey = keys.find(key => key.includes(this.LAST_NAME_KEY));
      const emailKey = keys.find(key => key.includes(this.EMAIL_KEY));
      const roleKey = keys.find(key => key.includes(this.ROLE_KEY));
      const uidKey = keys.find(key => key.includes(this.UID_KEY));
      if (firstNameKey) {
        this.entity.firstName = decoded[firstNameKey]
      }
      if (lastNameKey) {
        this.entity.lastName = decoded[lastNameKey]
      }
      if (emailKey) {
        this.entity.email = decoded[emailKey]
      }
      if (roleKey) {
        this.entity.role = decoded[roleKey]
      }
      if (uidKey) {
        this.entity.id = decoded[uidKey]
      }
    }
  };

  fetchUser = async () => {
    const response = await this.repository.fetchCurrent(true);
    this.setEntityFromResponse(response);
  };

  async loginUser(): Promise<models.Errors | undefined> {
    window.location.assign(B2B_CONNECTION);
    return Promise.resolve(undefined);
  }

  logoutUser = async () => {
    Cookies.remove('PCI_TOKEN');
    window.location.assign(B2B_CONNECTION);
  };

  @action
  clearUser = () => {
    // Reset only authenticated  users with ID (LOGOUT SIGNAL)
    if (this.entity.id && this.entity.authenticated) {
      this.entity = new CurrentUser();
    }
  };

  /**
   * Override parent to send global event about login user.
   * @memberof GeneralUser
   */
  @action
  setEntityFromResponse(response: ApiResponse<CurrentUser>) {
    if (response.status && response.entity) {
      this.entity = response.entity;
      // If you uncomment this, function with save language in cookie stop working
      // LocaleProvider.ts.changeLocaleByUserLang(this.entity.lang);
      EventBus.trigger(LOGIN_USER);
    }
  }

  @action
  setApplicationName(name: string) {
    this.applicationName = name;
  }

  get role() {
    return this.entity.role;
  }
}
