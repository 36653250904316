import { injectable } from 'inversify';
import BaseAsociatedRepository from './BaseAsociatedRepository';
import ApiClient from '@util/ApiClient';
import { plainToClass } from '@eman/class-transformer';
import StatisticsItem from '@model/StatisticsItem';

@injectable()
export default class MarketRepository extends BaseAsociatedRepository<StatisticsItem> {
  get classModelName(): string {
    return 'Market';
  }

  constructor() {
    super(StatisticsItem, 'admin/statistics/search/$ID', 'market');
  }

  fetchMarketInfo(market: string | number, pagination: Pagination, order: OrderOptions, filters: any): Promise<{ list: StatisticsItem[], total: number, others: any }>  {
    const config = {
      url: `${this.uri}`,
      method: 'POST' as 'POST',
      id: 'FETCH_ALL_EXTENSIONS',
      data: {
        page: pagination.page,
        pageSize: pagination.pageSize,
        sort: {
          propertyName: order.field,
          sortOrder: order.direction === 'asc' ? 1 : 2
        },
        ...filters
      }
    }

    return ApiClient.fetchData(config)
      .then((response) => {
        const items = response.results || response;
        const data = {
          total: response.totalResults || 0,
          list: plainToClass(this.model, items || []) as any[],
          others: response.others || {},
          original: response,
        };
        return data;
      });
  }
}
