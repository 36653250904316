import ResourceComponent from '@component/ResourceComponent';

import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { toJS } from 'mobx';

import React from 'react';

import EventBus, { SHOW_NOTIFICATION, SHOW_TOAST } from '@util/EventBus';

import DataTable, { ExportProps } from '@eman/emankit/DataTable';
import { AlignType, DataBodyProps, SortOrder } from '@eman/emankit/DataTable/DataBody';
import { HeaderProps } from '@eman/emankit/DataTable/HeaderBar';
import { FilterBarProps } from '@eman/emankit/FilterBar';
import FilterItem from '@eman/emankit/FilterBar/FilterItem';
import { localize } from "@util/Localization";
import { EXPORT_MAX_RECORDS } from "../../config";

/**
 * Abstract base list for sortable list components.
 */
export default abstract class BaseList<TModel extends models.Base, TViewModel extends ViewModel.List<TModel>, OtherProps = {}, OtherState = {}> extends ResourceComponent<OtherProps, OtherState> {

  // View Model
  // tslint:disable-next-line:member-ordering
  abstract vm: TViewModel;

  // Searchable is by default
  // tslint:disable-next-line:member-ordering
  searchable: boolean = true;
  selectableRows: boolean = true;

  // Array for export formats
  // tslint:disable-next-line:member-ordering
  exportFormats: string[] = [];

  tableWidth: string = '100%';

  // custom text for empty row
  emptyRow?: string;

  className?: string;

  // Abstract methods to create table
  abstract headerProps(): HeaderProps | undefined;

  abstract dataProps(): Omit<Omit<Omit<DataBodyProps, "emptyRow">, "data">, "value">;

  componentDidMount() {
    // Dont change persisted filters, this list VM are used as signletons.

    // Merge filters from route (they never will have columns & visible filters)
    const settings = {};

    // TODO: specify user default settings
    // if (this.user) {
    //   settings = {
    // TODO: specify user default settings
    // ...this.user.defaultSettings(this.modelName) || {}
    // };
    // }

    const queryParams = this.router.getQuery() || {};
    // tslint:disable:no-string-literal
    if (queryParams && queryParams['overrideFilters'] === "false") {
      const filtersValues = omit(queryParams, ['overrideFilters', 'pageSize', 'page', 'save']);
      settings['filters'] = {};
      for (const key in filtersValues) {
        if (filtersValues.hasOwnProperty(key)) {
          const filterItem = this.filterProps().filters!.find((item: any) => {
            if (Array.isArray(item.id)) {
              const keys = key.split(',');
              return isEqual(keys, item.id);
            } else {
              return item.id === key;
            }
          });

          // Ignore invalid filter params
          if (filterItem) {
            let operator: FilterOperator = "in";

            if (filterItem.operator !== undefined) {
              operator = filterItem.operator;
            } else if (filterItem.type !== undefined) {
              operator = FilterItem.defaultOperator(filterItem.type);
            }

            settings['filters'][key] = {
              values: filtersValues[key],
              operator,
            }
          }
        }
      }
    }

    // Propagate pagination from URL
    if (queryParams.pageSize && queryParams.page) {
      settings['pagination'] = {
        page: parseInt(queryParams.page as string, 10),
        pageSize: parseInt(queryParams.pageSize as string, 10)
      }
    }

    if (queryParams.q !== undefined) {
      this.vm.setSearchValue(queryParams.q as string);
    }

    // tslint:disable:no-string-literal
    if (queryParams.save !== undefined) {
      settings['save'] = queryParams.save !== "false";
    }

    if (Object.keys(settings).length > 0) {
      this.vm.setSettings(settings);
    }

    this.initVM();
  }

  initVM() {
    this.vm.init();
  }

  /**
   * More items to render above the table?
   */
  otherRender(): React.ReactNode {
    return null;
  }

  /**
   * Override this to modify.
   */
  filterProps(): Omit<FilterBarProps, "localization"> {
    return {};
  }


  /* displayProps(): Omit<DisplayBarProps, "localization"> | undefined {
    return {
      options: this.vm.displaySettings.map(item => ({name: item.name, id: item.id, default: item.default})),
      onSave: this.onDisplaySave,
      onRemove: this.onDisplayRemove,
      onChange: this.onDisplayChange,
      value: this.vm.display ? this.vm.display.id : ''
    };
  } */

  /**
   * Default rendering.
   */
  renderRow = (item: TModel, column: string): React.ReactNode => {
    return item[column];
  };

  /**
   * Update query params.
   */
  updateQuery = () => {

    const filters: any = {
      ...toJS(this.vm.selectedItems)
    };

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        filters[key] = filters[key].values;
      }
    }

    const queryParams: any = {
      ...filters,
      ...this.vm.pagination,
      q: this.vm.searchValue,
    };

    if (Object.keys(this.vm.selectedItems).length > 0) {
      queryParams.overrideFilters = false;
    }

    this.router.updateQuery(queryParams);
  };

  onSortChange = (sort: SortOrder): void => {
    this.vm.setOrder({ field: sort.key, direction: sort.sort });
  };

  onPageChange = (page: number, pageSize: number): void => {
    this.vm.setPageAndPageSize(page, pageSize);
    this.updateQuery();
  };

  onSearchConfirm = () => {
    this.vm.fetchList();
    this.updateQuery();
  };

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    if (reset) {
      this.vm.setSearchValue(undefined);
    }

    this.vm.setFilters(values, visible, undefined, true);
    this.updateQuery();
  };

  onColumnsChange = (columns: string[]) => {
    localStorage.setItem('CAMPAIGN_COLUMNS', JSON.stringify(columns));
    this.vm.setColumns(columns);
  };

  componentWillUnmount() {
    this.vm.cleanUp();
  }

  createField(id: string, { label, nosort = false, align = AlignType.Left, tooltip = false, alwaysShow = false, hideLabel = false, childrenOpener = false, width, childrenSelector, className }: { label?: string, nosort?: boolean, align?: AlignType, tooltip?: boolean, alwaysShow?: boolean, hideLabel?: boolean, width?: number, childrenOpener?: boolean, childrenSelector?: string, className?: string }) {
    return {
      id,
      label: label || this.ta(id),
      nosort,
      tooltip,
      alwaysShow,
      hideLabel,
      align,
      width,
      childrenOpener,
      childrenSelector,
      className
    };
  }

  createFieldWithSize(key: string, width: number) {
    return this.createField(key, { width });
  }

  createFieldSimple(key: string) {
    return this.createField(key, {});
  }

  paginationProps() {
    // Make pagination controlled component
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange
    }
  }

  renderBottom(): React.ReactNode {
    return null;
  }

  render(): React.ReactNode {
    const { vm } = this;

    if (!vm) {
      return null;
    }

    const dataProps = {
      ...this.dataProps(),
      value: this.renderRow,
      data: vm.list,
      sortBy: vm.order ? {
        key: vm.order.field,
        sort: vm.order.direction,
      } : undefined,
      selectedColumns: vm.columns,
      onSortChange: this.onSortChange,
      onColumnsChange: this.onColumnsChange,
      selectedRows: vm.selectedRows,
      onRowSelection: vm.setSelectedRows,
      emptyRow: this.emptyRow,
      selectableRows: this.selectableRows,
      loading: vm.loading,
    };

    const filterProps = this.filterProps();
    if (filterProps) {
      if (filterProps.filters) {
        filterProps.filters.forEach((value) => {
          value.values = toJS(value.values)
        });
      }

      filterProps.onFilterChange = this.onFilterChange;
      filterProps.selected = toJS(vm.selectedItems);
      filterProps.visible = vm.visibleFilters;

      if (this.searchable) {
        filterProps.search = {
          placeholder: this.ta("filter_helper"),
          suggestions: [],
          autocomplete: this.vm.autocomplete,
          value: this.vm.searchValue,
          onChangedValue: this.vm.setSearchValue,
          onSearchConfirm: this.onSearchConfirm,
        };
      }
    }

    let exportProps: ExportProps | undefined;
    if (this.exportFormats.length > 0) {
      exportProps = {
        formats: this.exportFormats,
        maxRecords: EXPORT_MAX_RECORDS,
        onExport: async (format: string) => {
          const response = await vm.startExport(format);

          if (response) {
            EventBus.trigger(SHOW_NOTIFICATION);
            EventBus.trigger(SHOW_TOAST, localize('export.success'));
          }
        }
      };
    }

    return (
      <div {...(!!this.className && { className: this.className })}>
        {this.otherRender()}
        <DataTable
          width={this.tableWidth}
          headerProps={this.headerProps()}
          filterProps={filterProps}
          exportProps={exportProps}
          dataProps={dataProps}
          paginationProps={this.paginationProps()}
        // displayProps={this.displayProps()}
        />
        {this.renderBottom()}
      </div>
    )
  }
}
