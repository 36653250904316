
import { uriHelper } from 'src/config';
import Statistics from '@view/Admin/Statistics';
import StatisticsShow from '@view/Admin/Statistics/Show';
import BaseRouter from './Base';

export const statisticsRoutes: AppRoute[] = [
  {
    path: uriHelper.statistics_uri(),
    exact: true,
    component: Statistics,
  },
  {
    path: uriHelper.show_statistics_uri(),
    exact: false,
    title: `show.statistics`,
    component: StatisticsShow,
  },
];

export default class StatisticsRouter extends BaseRouter {
  constructor(props: any) {
    super(props);

  //   if (!this.user.allowToPage(UserRightsPages.EMPLOYEES)) {
  //     employeesRoutes.splice(0, 0, {
  //       path: uriHelper.employees_uri(),
  //       exact: true,
  //       redirect: () => uriHelper.show_employees(this.user.entity.id),
  //     });
  //   }
  }

  render() {
    return this.pageRouteRender(statisticsRoutes);
  }
}
