import { observer } from "mobx-react";
import React from "react";

import AutoCompleteInput from "@eman/emankit/AutoCompleteInput";

import BindingElement, { BindingProps } from "./index";

@observer
export default class AutocompleteBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (value: FormUtils.SelectOption | string) => {
    this.setValue(this.parseValue(value));
  };

  // In autocomplete with FormUtils.SelectOption behaviour is different, we have to provide
  // designated method which returns SelectOption for current target. Unfortunattely those
  // are not properties but computable getters and setter, so `get` from `mobx` does not work.
  protected get value() {
    const { target, property } = this.props as any;
    return target[property];
  }

  private suggestionValue(suggestion: FormUtils.SelectOption | string): string {
    if (typeof suggestion === 'string') {
      return suggestion;
    } else {
      return suggestion.label as string;
    }
  }

  private parseValue(value: FormUtils.SelectOption | string): string {
    if (typeof value === 'string') {
      return value;
    } else {
      return value.label as string;
    }
  };

  // tslint:disable-next-line:member-ordering
  render() {
    let onInputChange: any;
    if (this.props.submitAnyUserInput) {
      onInputChange = this.handleValueChanged;
    }

    return(
      <AutoCompleteInput<FormUtils.SelectOption>
        inputProps={ { placeholder: this.props.placeholder, iconStop: this.props.iconStop, disabled: this.props.disabled } }
        searchSuggestions={this.props.searchSuggestions}
        onSelected={this.handleValueChanged}
        value={this.value}
        suggestions={[]}
        getInitialSuggestions={this.props.getInitialSuggestions}
        getSuggestionValue={this.suggestionValue}
        onInputChange={onInputChange}
        renderSuggestionsOnFocus={this.props.renderSuggestionsOnFocus}
      />
    );
  }
}
