import { observer } from "mobx-react";
import React from "react";

import Marks from "@eman/emankit/Marks";

import BindingElement, { BindingProps } from "./index";

@observer
export default class MarkBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (mark: number) => {
    this.setValue(mark);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    return (
      <Marks onChange={this.handleValueChanged} value={this.value} label={this.props.ownLabel} />
    );
  }
}
