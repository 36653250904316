import { action, observable } from "mobx";

import EventBus, { REFRESH_USER_FILTER_SETTINGS, SCROLL_TOP } from '@util/EventBus';

import BaseModel from "@model/BaseModel";
import ViewModel from "@vm/ViewModel";

export default abstract class ItemsViewModel<TModel extends BaseModel, TRepository extends Repository<TModel>> extends ViewModel<TModel, TRepository> implements ViewModel.Items<TModel> {
  /**
   * Items collection.
   */
  @observable list: TModel[] = [];

  /**
   * Filter settings.
   */
  @observable selectedItems: FilterValues;

  /**
   * Visible filter items.
   */
  @observable visibleFilters: string[];

  /**
   * Save ListQuery (send save == false to API).
   */
  save: boolean = true;

  /**
   * Preference prefix - recognize Public vs Admin section
   */
  preferencePrefix: string;

  /**
   * Fetch items from server via repository.
   */
  async fetchList(scrollTop: boolean = true, save: boolean = true) {
    this.currentlyFetching = true;

    const items = await this.repository.fetchItems({
      filters: this.selectedItems,
      loading: this.loading,
      save: this.save && save,
      preferencePrefix: this.preferencePrefix,
    });

    if (this.save && save) {
      EventBus.trigger(REFRESH_USER_FILTER_SETTINGS);
    }

    this.setList(items.items);

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    this.currentlyFetching = false;
  }

  /**
   * Change filtering.
   *
   * @param {FilterValues} filters
   * @param {string[]} visible Visible filter list
   * @param {boolean} skipFetch Don't call fetchList(), view take care about it itself
   * @param {boolean} save Save the filters to user preference
   * @memberof ListViewModelBase
   */
  @action
  setFilters(selectedItems: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean) {
    this.selectedItems = selectedItems;

    if (visible !== undefined) {
      this.visibleFilters = visible;
    }

    if (save !== undefined) {
      this.save = save;
    }

    if (skipFetching !== true) {
      this.fetchList();
    }
  }

  /**
   * Test if filter is set to entity value, this is really for sub view models in entities.
   *
   * @param {string} key
   * @param {string[]} value
   * @memberof ListViewModel
   */
  hasFilter(key: string, value: models.Base) {
    if (value.id) {
      return this.selectedItems && this.selectedItems[key].values === [ value.stringId! ];
    } else {
      return false;
    }
  }

  /**
   * Call parent and add save == false.
   */
  setAsChildVM() {
    super.setAsChildVM();
    this.save = false;
  }

  /**
   * Initialize (method to override in children, for loading more required items)
   */
  init(...rest: any) {
    return this.fetchList();
  }

  /**
   * Set items from api
   *
   * @param {TModel[]} list
   */
  @action
  setList(list: TModel[]) {
    this.list = list;
  }
}
