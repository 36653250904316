import { action } from 'mobx';
import React  from 'react';

import Alert from '@eman/emankit/Alert';
import { BaseColor } from '@eman/emankit/Colors';
import Icon, { IconType } from '@eman/emankit/Icon';

import EventBus, { SCROLL_TO_FIRST_ERROR } from "@util/EventBus";

export { default as FormCollapsible } from './FormCollapsible';
export { default as GenericFormField } from './GenericFormField';
export { setValue as setValue } from './BindingElement/index';
export { default as AutocompleteBox } from './BindingElement/AutoCompleteBox';
export { default as CheckBox } from './BindingElement/CheckBox';
export { default as CheckBoxArray } from './BindingElement/CheckBoxArray';
export { default as DatePickerBox } from './BindingElement/DatePickerBox';
export { default as MarkBox } from './BindingElement/MarkBox';
export { default as PasswordBox } from './BindingElement/PasswordBox';
export { default as SelectBox } from './BindingElement/SelectBox';
export { default as SelectWithSearchBox } from './BindingElement/SelectWithSearchBox';
export { default as TextAreaBox } from './BindingElement/TextAreaBox';
export { default as TextBox } from './BindingElement/TextBox';
export { default as TimeBox } from './BindingElement/TimeBox';
export { default as ReactTagsBox } from './BindingElement/TagsBox';
export { default as MultiSelectBox } from './BindingElement/MultiSelectBox';

export const fieldError = (target: any, property: string | undefined): string | undefined => {
  let ret;
  if (target && property && target.errors) {
    if (target.errors.has(property)) {
      ret = target.errors.get(property);
    } else if (property.endsWith('_id')) {
      const propertyCopy = property.replace('_id', '');
      ret = target.errors.get(propertyCopy);
    } else if (property.endsWith('_form')) {
      const propertyCopy = property.replace('_form', '');
      ret = target.errors.get(propertyCopy);
    }
  }

  if (ret && ret.join) {
    ret = ret.join(', ');
  }

  return ret;
};

export const scrollToFirstError = () => {
  // @TODO rename to emankit after components update
  requestAnimationFrame(() => {
    const errorGroup = document.getElementsByClassName("respectkit__collapsible_container error")[0];

    if (errorGroup) {
      const errorItem = errorGroup.getElementsByClassName("respect__input_container error")[0];

      if (errorItem) {
        errorItem.scrollIntoView({ behavior: 'smooth', block: "center" });
      } else {
        errorGroup.scrollIntoView({behavior: 'smooth', block: "center" });
      }
    }
  });
};

// Global form component, which renders :base errors!
export class Form extends React.Component<FormUtils.Props> {

  componentDidMount(): void {
    EventBus.on(SCROLL_TO_FIRST_ERROR, scrollToFirstError);
  }

  componentWillUnmount(): void {
    EventBus.off(SCROLL_TO_FIRST_ERROR, scrollToFirstError);
  }

  @action.bound
  private onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (this.props.onFormSubmit) {
      e.preventDefault();
      this.props.onFormSubmit();
    }
  }

  @action.bound
  private onReset(e: React.FormEvent<HTMLFormElement>) {
    if (this.props.onFormReset) {
      e.preventDefault();
      this.props.onFormReset();
    }
  }

  // tslint:disable-next-line:member-ordering
  render() {
    let baseErrors;
    if (this.props.baseErrors) {
      baseErrors = this.props.baseErrors.map((error: string, index: number) => {
        return (
          <li key={`index_error_${index}`}>
            {error}
          </li>
        )
      });

      baseErrors = (
        <Alert color={BaseColor.Red} className="with-icon" style={{ marginTop: '10px' }}>
          <Icon icon={IconType.Warning} className="icon" />
          <span>
            <ul>
              {baseErrors}
            </ul>
          </span>
        </Alert>
      );
    }

    return (
      <form onSubmit={this.onSubmit} onReset={this.onReset}>
        {baseErrors}
        {this.props.children}
      </form>
    );
  }
}

export default Form;
