import BaseComponent from '@component/BaseComponent';
import { localize } from "@util/Localization";

export default abstract class ResourceComponent<TProps = {}, TState = {}> extends BaseComponent<TProps, TState> {

  abstract get modelName(): string;

  prefix: string = '';

  suffix: string = '';

  externalLocs: Record<string, string> = {};

  get nameSpace(): string {
    let nameSpace = this.modelName;

    if (this.prefix) {
      nameSpace = `${this.prefix}.${this.modelName}`;
    }

    if (this.suffix) {
      nameSpace += `.${this.suffix}`;
    }

    return nameSpace;
  }

  /**
   * Translate atribute according to current model.
   *
   * @param attribute
   */
  ta = (attribute: string): string => {
    return localize(`${this.modelName.toLowerCase()}.${attribute}`, this.externalLocs);
  };

  /**
   * Translate global code according to current nameSpace
   *
   * @param code
   */
  tg = (code: string): string => {
    return localize(`${this.nameSpace}.${code}`, this.externalLocs);
  };
}
